import React, { useState } from 'react'
import { TableRow, TableCell, Button } from "@mui/material"
import { formatDate } from '../../../../utils/helperDateTime'
import EditIcon from "@mui/icons-material/Edit";
import TerminalHolidayModal from '../../../popup/TerminalInfoPopup/TerminalHolidayModal';

const TerminalHolidayRow = ({ data, handleSort }) => {

    const [isEditActive, setIsEditActive] = useState(false)

    const { terminalName, holiday, description } = data

    console.log(terminalName)

    return (
        <>
            <TableRow className="tableRow" key={terminalName}>
                <TableCell className="containerId">
                    {terminalName || "--"}
                </TableCell>
                <TableCell >{description || "--"}</TableCell>
                <TableCell >{formatDate(holiday) || "--"}</TableCell>
                <TableCell>
                    <div className='dot-box'>
                        <EditIcon onClick={() => setIsEditActive(true)} className='me-3' />
                    </div>
                </TableCell>
            </TableRow>

            {
                isEditActive && <TerminalHolidayModal open={isEditActive} onClose={() => setIsEditActive(false)} title={"Update Holiday"} btn={"Update"} data={data} fetchDetails={handleSort} />
            }
        </>
    )
}

export default TerminalHolidayRow