import { createContext, useState } from "react";

const AppReadyContext = createContext();

export const AppReadyProvider = ({ children }) => {
  const [appReady, setAppReady] = useState(false);

  return (
    <AppReadyContext.Provider value={{ appReady, setAppReady }}>
      {children}
    </AppReadyContext.Provider>
  );
};

export default AppReadyContext;
