import React, { useContext, useEffect, useState } from 'react'
import { TableContainer, Paper } from "@mui/material"

import AppReadyContext from '../../../context/AppReadyContext';
import { useDropDown } from '../../../context/DropDownContext';

import PageHeader from '../../ui/PageHeader';
import ThemeBtn from '../../ui/ThemeBtn';
import Loader from '../../ui/Loader';
import PaginationBar from '../../ui/PaginationBar';
import SearchNameDropDown from '../../ui/DropDown/SearchNameDropDown';

import { generateQueryParams, handleList } from '../../../utils/helperPages';
import SuperProfileModal from '../../popup/TruckerPopup/SuperProfileModal';
import UserAccessCheck from '../../UserAccess/UserAccessCheck';
import ProfileTable from './ProfileTable/ProfileTable';

import { ListOfProfileData, TruckerLists } from '../../../axios/api';

const ProfileList = () => {

    const { appReady } = useContext(AppReadyContext);
    const { truckerList, setTruckerList } = useDropDown()

    const [profilesData, setProfilesData] = useState([])
    const [loading, setLoading] = useState(false);
    const [isAddActive, setIsddActive] = useState(false)

    const [selectedTrucker, setSelectedTrucker] = useState([])

    const [sortBy, setSortBy] = useState("containerID"); // Column to sort by
    const [sortOrder, setSortOrder] = useState("asc"); // Sorting order (asc, desc)

    const [page, setPage] = useState(0);
    const [pageNo, setPageNo] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRecords, setTotalRecords] = useState(10);


    const handleSort = async (column) => {
        setLoading(true);
        if (sortBy === column && sortOrder === "asc") {
            setSortOrder("desc");
        } else {
            setSortBy(column);
            setSortOrder("asc");
        }

        let newSortOrder = sortOrder === "asc" ? "desc" : "asc";

        setSortBy(column);
        setSortOrder(newSortOrder);

        const paramsObject = {
            PageNumber: pageNo,
            PageSize: rowsPerPage,
            sortingColumn: column,
            sortingOrder: newSortOrder,
        }

        profileCall(generateQueryParams(paramsObject))
    }


    const handleSearch = () => {
        setLoading(true)

        const paramsObject = {
            Name: selectedTrucker,
            PageNumber: pageNo,
            PageSize: rowsPerPage,
            sortingColumn: sortBy,
            sortingOrder: sortOrder,
        }

        profileCall(generateQueryParams(paramsObject))
    }


    const clearFilters = () => {
        setLoading(true)
        setSelectedTrucker([]);
        setRowsPerPage(10);
        setSortBy("containerNumber");
        setSortOrder("asc");
        setPageNo(0);
        fetchProfiles(1, rowsPerPage)
    }


    const fetchProfiles = (pageNumber, pageSize) => {
        setLoading(true)

        const paramsObject = {
            PageNumber: pageNumber,
            PageSize: pageSize,
            sortingColumn: sortBy,
            sortingOrder: sortOrder,
        }

        const params = generateQueryParams(paramsObject)
        profileCall(params)
    }


    const profileCall = async (params) => {
        try {
            const response = await ListOfProfileData(params)
            const responseTrucker = await TruckerLists()
            setProfilesData(response.data.truckerslist)
            setTotalRecords(response.data.pageDetails.totalRecords);
            setTruckerList(responseTrucker.data)
            setLoading(false)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    useEffect(() => {
        fetchProfiles(1, rowsPerPage)
    }, [appReady])

    return (
        <>

            <UserAccessCheck pageName={"Operations"} subPage={"Trucker Profile"} />
            {
                appReady && <>
                    <div className="dashboard-containerxx db-ctr container-fluid page-content-main">
                        <PageHeader name={"Operations"} subName={"Trucker Profile"} />


                        <div className="summary-filters">
                            <div className='summary-filters'>
                                {/* Trucker Name DropDown */}
                                <SearchNameDropDown title={"Enter Profile Name"} type={"search"} options={handleList(truckerList)} selectedValue={selectedTrucker} setSelectedValue={setSelectedTrucker} />

                                <ThemeBtn name="Search" handleClick={handleSearch} disabled={selectedTrucker.length === 0} />
                                <ThemeBtn name="Reset" handleClick={clearFilters} />

                            </div>

                            <ThemeBtn name="Add Trucker Profile" handleClick={() => setIsddActive(true)} />

                        </div>

                        <div className="summary-section-3">
                            <TableContainer component={Paper}>
                                {
                                    loading ? <Loader /> : <>
                                        <ProfileTable data={profilesData} sortBy={sortBy} sortOrder={sortOrder} page={page} rowsPerPage={rowsPerPage} handleSort={handleSort} />
                                    </>
                                }

                                {
                                    totalRecords !== 0 && <PaginationBar page={page} setPage={setPage} pageNo={pageNo} totalRecords={totalRecords} fetchData={fetchProfiles} setPageNo={setPageNo} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
                                }

                            </TableContainer>
                        </div>
                    </div>

                    {
                        isAddActive && <SuperProfileModal open={isAddActive} onClose={() => setIsddActive(false)} title={"Add Trucker Profile"} btn={"Add"} fetchDetails={handleSort} />
                    }
                </>
            }
        </>

    )
}

export default ProfileList