export const checkPage = (userRole) => {
    switch (userRole) {
        case "Trucker Admin": return "Admin Portal"
        case "Super Admin": return "Operations"
    }
}

// handle the dynamic query for the api
export const generateQueryParams = (queryObject) => {
    const params = [];

    for (const key in queryObject) {
        if (queryObject.hasOwnProperty(key)) {
            if (key === "PageNumber" || key === "PageSize" || key === "sortingOrder" || key === "sortingColumn") {
                const value = queryObject[key];
                params.push(`${key}=${value}`);

            } else if (queryObject[key].length > 0) {
                const value = queryObject[key];
                params.push(`${key}=${value}`);
            }
        }
    }

    return params
}

// handle for the react select option list 
export const labelConvert = (list) => {
    let arr = []
    list.map((e) => {
        arr.push({ label: e, value: e })
    })
    return arr
}

// return the list of name and id 
export const labelIdConvert = (list) => {
    let uniqueIds = new Set();
    let arr = [];

    list.forEach((e) => {
        if (!uniqueIds.has(e.id)) {
            arr.push({ label: e.name, value: e.id });
            uniqueIds.add(e.id);
        }
    });

    return arr;
};

// return the list of name only 
export const handleList = (list) => {
    let uniqueIds = new Set();
    let arr = [];

    list.forEach((e) => {
        if (!uniqueIds.has(e.id)) {
            arr.push(e.name);
            uniqueIds.add(e.id);
        }
    });

    return arr;

    // list.map((e) => {
    //     arr.push(e.name)
    // })
    // return arr
}

// Handle The trucker name in the URL
export const decodeURL = (name) => {
    return name.replace(/_/g, ' ')
}
export const encodeURL = (name) => {
    return name.replace(/\s/g, '_')
}

// handle the object the replace the empty string with the null
export const handleStringWithNull = (obj) => {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map((item) => handleStringWithNull(item));
    }

    return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [
            key,
            value === '' ? null : handleStringWithNull(value),
        ])
    );
}
