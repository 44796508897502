import React, { createContext, useContext, useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { SnackbarContent } from '@mui/material';

const SnackbarContext = createContext();

export function useSnackbar() {
    return useContext(SnackbarContext);
}

export function SnackbarProvider({ children }) {

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');

    useEffect(() => {
        if (open) {
            const timer = setTimeout(() => {
                setOpen(false);
            }, 5000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [open]);

    const showSnackbar = (message, severity) => {
        setMessage(message);
        setSeverity(severity);
        setOpen(true);
    };

    const closeSnackbar = () => {
        setOpen(false);
    };

    return (
        <SnackbarContext.Provider value={showSnackbar}>
            {children}
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={open}
                autoHideDuration={15000}
                onClose={closeSnackbar}
            >
                <SnackbarContent sx={{
                    backgroundColor: severity === "success" ? "#30ad62" : "#F75D59",
                }} message={message} />
            </Snackbar>
        </SnackbarContext.Provider>
    );
}