import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from "./context/AuthContext";
import { AppReadyProvider } from "./context/AppReadyContext";
import { NotificationProvider } from "./context/NotificationContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { DropDownProvider } from './context/DropDownContext';
import { ReportProvider } from './context/ReportContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <AuthProvider>
    <AppReadyProvider>
      <NotificationProvider>
        <DropDownProvider>
          <ReportProvider>
            <App />
          </ReportProvider>
        </DropDownProvider>
      </NotificationProvider>
    </AppReadyProvider>
  </AuthProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
