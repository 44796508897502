import React, { useContext } from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TruckerProfile from './TruckerProfile/TruckerProfile';
import DeliveryLocationList from './DeliveryLocationProfile/DeliveryLocationList';
import ReportContext from '../../context/ReportContext';

const AdminRoute = () => {

    const { setReportType } = useContext(ReportContext);
    setReportType("adminPortalIcon");

    return (
        <>
            <Routes >
                <Route path="/trucker-profile" element={<TruckerProfile />} />
                <Route path="/delivery-location-profile" element={<DeliveryLocationList />} />
            </Routes>
        </>
    )
}

export default AdminRoute