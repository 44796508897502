import React from 'react'
import Select from 'react-select';
import { labelConvert } from '../../../utils/helperPages'
import { useDropDown } from '../../../context/DropDownContext'

const SearchNameDropDown = ({ title, type, options, selectedValue, setSelectedValue }) => {

    // Select Dropdown for Terminal which is going to use in whole project


    const handleChange = (e) => {
        if (e === null) {
            setSelectedValue([])
        } else {
            const arr = []
            e.map((el) => {
                arr.push(el.value)
            })
            console.log(arr)
            setSelectedValue(arr)
        }
    }

    return (
        <>
            <div>
                <div className="filter-label">{title}</div>
                <Select
                    className='select-custom search-width'
                    isClearable={true}
                    isMulti
                    value={selectedValue.length === 0 ? null : labelConvert(selectedValue)}
                    options={labelConvert(options)}
                    onChange={handleChange}
                    required
                />
            </div>
        </>
    )
}

export default SearchNameDropDown