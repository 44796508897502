import React from 'react'
import { Link } from 'react-router-dom'
import logo from "../assets/logo.png";
import "./Logout.css";

const PostLogoutScreen = () => {
    return (
        <div>
            <div className="background" role="presentation"></div>
            <div className="outer">
                <div className="middle">
                    <div className="inner ">
                        <img
                            className="banner-logo"
                            role="presentation"
                            src={logo}
                            width={100}
                            height={50}
                        />
                        <div role="alert" aria-live="assertive">
                            <div className="row text-title">
                                <div id="SignOutStatusMessage">
                                    You signed out of your account
                                </div>
                            </div>
                            <div className="row text-body text-block-body">
                                <div id="SignOutStatusMessage">
                                    <p>
                                        Click <Link to="/">here</Link> to log in again.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            id="InitiatorRedirectOption"
                            className="row text-body text-block-body"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PostLogoutScreen