import React from 'react'
import { Table, TableHead, TableRow, TableCell, TableSortLabel, TableBody } from "@mui/material"
import SuperTruckertableRow from './SuperTruckertableRow'
import { tableHead, tableRow } from '../../../../utils/helperInline'

const SuperTruckerTable = ({ data, sortBy, sortOrder, page, rowsPerPage, handleSort }) => {

    const tableHeading = [
        { title: "TERMINAL NAME", sort: "terminalName" },
        { title: "CAPACITY", sort: "capacity_Per_Day" },
        { title: "WAIT WINDOW", sort: "scheduler_Wait_Window" },
        { title: "DAYS", sort: "day" },
        { title: "OPEARTION SLOTS", sort: "operating_Slot" },
        { title: "CUT-OFF TIME", sort: "cutoff_Time" },
    ]

    return (
        <>
            <Table>
                <TableHead sx={tableHead}>
                    <TableRow>
                        {
                            tableHeading.map((item) => (
                                <TableCell>
                                    <TableSortLabel
                                        active={sortBy === item.sort}
                                        direction={sortOrder}
                                        onClick={() => handleSort(item.sort)}
                                    >
                                        {item.title}
                                    </TableSortLabel>
                                </TableCell>
                            ))
                        }
                        <TableCell>ACTION</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className="tableBody" sx={tableRow}>
                    {
                        data.length !== 0 ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data) => (
                            <SuperTruckertableRow data={data} handleSort={handleSort} />
                        )) : <>No Record Found</>
                    }
                </TableBody>
            </Table>
        </>
    )
}

export default SuperTruckerTable