import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import PageLoader from "../components/ui/PageLoader";

const LoginButton = () => {
  const { instance } = useMsal();

  useEffect(() => {
    const handleLogin = async () => {
      try {
        // Clear local storage
        localStorage.clear();

        // Get the user's email from some source (e.g., a prompt)
        const userEmail = true;
        const loginResponse = null;
        // Initiate login
        if (userEmail) {
          // Initiate login with loginHint
          loginResponse = await instance.loginRedirect({
            loginHint: "",
          });
        }
      } catch (error) {
        console.error("Login error:", error);
      }
    };

    const timeoutId = setTimeout(() => {
      handleLogin();
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [instance]);

  return <PageLoader />;
};

export default LoginButton;
