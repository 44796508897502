import React, { useState } from 'react'
import { StyledDialog, StyledDialogContent, StyledDialogTitle } from '../popupstyled'
import { IconButton } from '@mui/material'
import CloseIcon from "@mui/icons-material/Close";
import { Col, Container, Row } from 'react-bootstrap'

const DeleteProfileModal = ({ open, onClose, title }) => {

    const [submitLoader, setSubmitLoader] = useState(false)

    return (
        <StyledDialog
            open={open}
            onClose={onClose}
            // onKeyDown={handleKeyDown}
            className="edit-popup"
        >
            <StyledDialogTitle className="edit-title">
                {title}
                <IconButton edge="end" color="inherit" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </StyledDialogTitle>
            <StyledDialogContent className="edit-container">
                <Container fluid className="p-0">
                    <Row>
                        <Col lg={12}>
                            <div className="input-container">
                                <p>Are you sue you want to delete the Profile.</p>
                            </div>
                        </Col>
                    </Row>

                    <div className='d-flex justify-content-end gap-4'>
                        <button className='cancel-btn' onClick={onClose}>Cancel</button>
                        <button className='submit-btn' type='submit'>
                            {
                                submitLoader ? <div class="spinner-border spinner-size" role="status" />
                                    : <>Delete</>
                            }

                        </button>
                    </div>
                </Container>
            </StyledDialogContent>
        </StyledDialog>
    )
}

export default DeleteProfileModal