import React, { useContext, useState, useEffect } from 'react'
import { TableContainer, Paper, } from "@mui/material";

import { generateQueryParams, handleList } from '../../../utils/helperPages';
import PageHeader from '../../ui/PageHeader';

import UserAccessCheck from '../../UserAccess/UserAccessCheck';
import SuperLocationTable from './LocationTable/SuperLocationTable';

import AppReadyContext from '../../../context/AppReadyContext';

import ThemeBtn from '../../ui/ThemeBtn';
import Loader from '../../ui/Loader';
import PaginationBar from '../../ui/PaginationBar';

import SuperLocationModal from '../../popup/DeliveryLocationPopup/SuperLocationModal';
import { useDropDown } from '../../../context/DropDownContext';
import { useNavigate } from 'react-router-dom';
import SelectFilter from '../../ui/DropDown/SelectFilter';
import { DeliveryLocationData, DeliveryLocationLists, TerminalLists, TruckerClientList, TruckerLists } from '../../../axios/api';

const SuperDeliveryLoc = () => {

    const { appReady } = useContext(AppReadyContext);
    const navigate = useNavigate()
    const { truckerList, locationList, clientList, setTruckerList, setLocationList, setClientList } = useDropDown()

    const [deliveryData, setDeliveryData] = useState([])
    const [loading, setLoading] = useState(false);
    const [isAddActive, setIsddActive] = useState(false)

    const [selectedClient, setSelectedClient] = useState([])
    const [selectedLocations, setSelectedLocation] = useState([]);
    const [selectedTrucker, setSelectedTrucker] = useState([])

    const [debouncedClient, setDebouncedClient] = useState([])
    const [debouncedLocation, setDebouncedLocation] = useState([])
    const [debouncedTrucker, setDebouncedTrucker] = useState([])

    const [sortBy, setSortBy] = useState("containerID"); // Column to sort by
    const [sortOrder, setSortOrder] = useState("asc"); // Sorting order (asc, desc)

    const [page, setPage] = useState(0);
    const [pageNo, setPageNo] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRecords, setTotalRecords] = useState(10);


    const handleSort = async (column) => {
        setLoading(true);
        if (sortBy === column && sortOrder === "asc") {
            setSortOrder("desc");
        } else {
            setSortBy(column);
            setSortOrder("asc");
        }

        let newSortOrder = sortOrder === "asc" ? "desc" : "asc";

        setSortBy(column);
        setSortOrder(newSortOrder);

        const paramsObject = {
            DeliveryLocation: selectedLocations,
            Clients: selectedClient,
            Trucker: selectedTrucker,
            PageNumber: pageNo,
            PageSize: rowsPerPage,
            sortingColumn: column,
            sortingOrder: newSortOrder,
        }

        deliveryCall(generateQueryParams(paramsObject))
    }

    const handleFilters = () => {
        setLoading(true);
        setPageNo(0);
        // Call the fetchContainerSummary function with the current pagination parameters
        fetchDeliveryLocation(1, rowsPerPage);
    }

    useEffect(() => {
        const terminalTimeout = setTimeout(() => {
            setDebouncedClient(selectedClient);
        }, 500);

        return () => {
            clearTimeout(terminalTimeout);
        };
    }, [selectedClient]);

    useEffect(() => {
        const terminalTimeout = setTimeout(() => {
            setDebouncedTrucker(selectedTrucker);
        }, 500);

        return () => {
            clearTimeout(terminalTimeout);
        };
    }, [selectedTrucker]);

    useEffect(() => {
        const terminalTimeout = setTimeout(() => {
            setDebouncedLocation(selectedLocations);
        }, 500);

        return () => {
            clearTimeout(terminalTimeout);
        };
    }, [selectedLocations]);

    useEffect(() => {
        handleFilters();
    }, [debouncedClient, debouncedLocation, debouncedTrucker]);

    const fetchDeliveryLocation = async (pageNumber, pageSize) => {
        setLoading(true)

        const paramsObject = {
            DeliveryLocation: selectedLocations,
            Clients: selectedClient,
            Trucker: selectedTrucker,
            PageNumber: pageNumber,
            PageSize: pageSize,
            sortingColumn: sortBy,
            sortingOrder: sortOrder,
        }

        deliveryCall(generateQueryParams(paramsObject))
    }


    const fetchClientTruckerLocation = async () => {
        try {
            const responseTrucker = await TruckerLists()
            const responseLocation = await DeliveryLocationLists()
            const responseClient = await TruckerClientList()
            // const responseTrucker = TruckerList()
            setTruckerList(responseTrucker?.data)
            setLocationList(responseLocation?.data)
            setClientList(responseClient?.data)
        } catch (error) {
            console.error("Error fetching filters:", error);
        }
    }

    const deliveryCall = async (params) => {
        try {
            const response = await DeliveryLocationData(params)
            setDeliveryData(response.data.deliveryLocationslist)
            setTotalRecords(response.data.pageDetails.totalRecords);
            setLoading(false)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }


    useEffect(() => {
        fetchDeliveryLocation(1, 10)
        fetchClientTruckerLocation()
    }, [appReady])


    return (
        <>
            <UserAccessCheck pageName={"Operations"} subPage={"Delivery Location Profile"} />
            {
                appReady && <>
                    <div className="dashboard-containerxx db-ctr container-fluid page-content-main">
                        <PageHeader name={"Operations"} subName={"Delivery Location Profile"} />

                        <div className="summary-filters">

                            <div className="summary-filters">
                                {/* Client DropDown */}
                                <SelectFilter title={"Client"} type={"client"} options={handleList(clientList)} selectedValue={selectedClient} setSelectedValue={setSelectedClient} />


                                {/* Trucker DropDown */}
                                <SelectFilter title={"Trucker"} type={"trucker"} options={handleList(truckerList)} selectedValue={selectedTrucker} setSelectedValue={setSelectedTrucker} />


                                {/* location DropDown */}
                                <SelectFilter title={"Delivery Location"} type={"location"} options={handleList(locationList)} selectedValue={selectedLocations} setSelectedValue={setSelectedLocation} />
                            </div>

                            <ThemeBtn name="Add Location" handleClick={() => setIsddActive(true)} />

                            <ThemeBtn name="Holiday List" handleClick={() => navigate('/operation/delivery-holiday')} />

                        </div>

                        <div className="summary-section-3">
                            <TableContainer component={Paper}>
                                {
                                    loading ? <Loader /> : <>
                                        <SuperLocationTable data={deliveryData} sortBy={sortBy} sortOrder={sortOrder} page={page} rowsPerPage={rowsPerPage} handleSort={handleSort} />
                                    </>
                                }

                                {
                                    totalRecords !== 0 && <PaginationBar page={page} setPage={setPage} pageNo={pageNo} totalRecords={totalRecords} fetchData={fetchDeliveryLocation} setPageNo={setPageNo} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
                                }

                            </TableContainer>
                        </div>
                    </div>

                    {
                        isAddActive && <SuperLocationModal open={isAddActive} onClose={() => setIsddActive(false)} title={"Add Delivery Location Details"} btn={"Add"} fetchDetails={handleSort} />
                    }
                </>
            }
        </>
    )
}

export default SuperDeliveryLoc