import React, { useContext, useEffect, useState } from 'react'
import { FormControl, Select, Checkbox, MenuItem, ListItemText, TableContainer, Button, Paper, } from "@mui/material";
import { MenuProps } from '../../../utils/helperInline';
import "./truckerprofile.css"

import AppReadyContext from '../../../context/AppReadyContext';
import { useAuth } from '../../../context/AuthContext';

import UserAccessCheck from '../../UserAccess/UserAccessCheck';
import TruckerProfileTable from './TruckerTable/TruckerProfileTable';
import TruckerProfileContainer from './TruckerProfileContainer';

import PageHeader from '../../ui/PageHeader';
import Loader from '../../ui/Loader';
import PaginationBar from '../../ui/PaginationBar';

import { generateQueryParams, handleList } from '../../../utils/helperPages';
import JSONDATA from "../../../utils/MockData/truckerProfile.json"
import ThemeBtn from '../../ui/ThemeBtn';
import ProfileModal from '../../popup/TruckerPopup/ProfileModal';
import TerminalRecordModal from '../../popup/TruckerPopup/TerminalRecordModal';
import { useDropDown } from '../../../context/DropDownContext';
import SelectFilter from '../../ui/DropDown/SelectFilter';
import { TerminalLists, TerminalsList, TruckerProfileData } from '../../../axios/api';


const TruckerProfile = () => {

    const { appReady } = useContext(AppReadyContext);
    const { terminalList, setTerminalList } = useDropDown()

    const [truckerData, setTruckerData] = useState([])

    const [loading, setLoading] = useState(false);
    const [isAddActive, setIsddActive] = useState(false)

    const [selectedTerminals, setSelectedTerminal] = useState([])
    const [debouncedTerminals, setDebouncedTerminals] = useState([])

    const [sortBy, setSortBy] = useState("truckerName"); // Column to sort by
    const [sortOrder, setSortOrder] = useState("asc"); // Sorting order (asc, desc)

    const [page, setPage] = useState(0);
    const [pageNo, setPageNo] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRecords, setTotalRecords] = useState(10);


    const handleSort = async (column) => {
        setLoading(true);
        if (sortBy === column && sortOrder === "asc") {
            setSortOrder("desc");
        } else {
            setSortBy(column);
            setSortOrder("asc");
        }

        let newSortOrder = sortOrder === "asc" ? "desc" : "asc";

        setSortBy(column);
        setSortOrder(newSortOrder);

        const paramsObject = {
            TerminalId: selectedTerminals,
            PageNumber: pageNo,
            PageSize: rowsPerPage,
            sortingColumn: column,
            sortingOrder: newSortOrder,
        }

        truckerCall(generateQueryParams(paramsObject))
    }

    const handleFilters = () => {
        setLoading(true);
        setPageNo(0);

        // Call the fetchTruckerProfile function with the current pagination parameters
        fetchTruckerProfile(1, rowsPerPage);
    }


    useEffect(() => {
        const statusTimeout = setTimeout(() => {
            setDebouncedTerminals(selectedTerminals);
        }, 500);

        return () => {
            clearTimeout(statusTimeout);
        };
    }, [selectedTerminals]);

    useEffect(() => {
        handleFilters();
    }, [debouncedTerminals]);

    const fetchTruckerProfile = async (pageNumber, pageSize) => {
        setLoading(true)

        const paramsObject = {
            Terminal: selectedTerminals,
            PageNumber: pageNumber,
            PageSize: pageSize,
            sortingColumn: sortBy,
            sortingOrder: sortOrder,
        }

        truckerCall(generateQueryParams(paramsObject))
    }


    // Get the Terminal
    const fetchTerminal = async () => {
        try {
            const response = await TerminalLists()
            // const sanitizedTerminals = response.terminals.filter(
            //     (terminal) => terminal !== ""
            // );
            // setTerminalList(sanitizedTerminals.sort());
            // setTerminalList(response.data);
            // setTerminalList(["CHA-NRA-ECT", "LAX TRM-B302", "LAX-TRM-J234"])
            setTerminalList(response.data)
        } catch (error) {
            console.error("Error fetching filters:", error);
        }
    }

    const truckerCall = async (params) => {
        try {
            const response = await TruckerProfileData(params)
            setTruckerData(response.data.truckerTerminalslist)
            setTotalRecords(response.data.pageDetails.totalRecords);
            setLoading(false)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }


    useEffect(() => {
        fetchTruckerProfile(1, rowsPerPage)
        fetchTerminal()
    }, [appReady])



    return (
        <>
            <UserAccessCheck pageName={"Admin Portal"} subPage={"Trucker Profile"} />
            {
                appReady && <>
                    <div className="dashboard-containerxx db-ctr container-fluid page-content-main">
                        <PageHeader name={"Admin Portal"} subName={"Trucker Profile"} />


                        <div className="summary-filters">
                            <div className='summary-filters'>
                                {/* Terminal DropDown */}
                                <SelectFilter title={"Terminal"} type={"terminal"} options={handleList(terminalList)} selectedValue={selectedTerminals} setSelectedValue={setSelectedTerminal} />

                                <ThemeBtn name="Add Details" handleClick={() => setIsddActive(true)} />

                            </div>

                            <TruckerProfileContainer />

                        </div>

                        <div className="summary-section-3">
                            <TableContainer component={Paper}>
                                {
                                    loading ? <Loader /> : <>
                                        <TruckerProfileTable data={truckerData} sortBy={sortBy} sortOrder={sortOrder} page={page} rowsPerPage={rowsPerPage} handleSort={handleSort} />
                                    </>
                                }

                                {
                                    totalRecords !== 0 && <PaginationBar page={page} setPage={setPage} pageNo={pageNo} totalRecords={totalRecords} fetchData={fetchTruckerProfile} setPageNo={setPageNo} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
                                }
                            </TableContainer>
                        </div>
                    </div>

                    {
                        isAddActive && <TerminalRecordModal open={isAddActive} onClose={() => setIsddActive(false)} title={"Add Terminal Record"} btn={"Add"} fetchDetails={handleSort} />
                    }
                </>
            }
        </>

    )
}

export default TruckerProfile