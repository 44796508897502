import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useSnackbar } from '../../../context/SnackbarContext';
import RequiredAstrict from '../../ui/RequiredAstrict';
import LocationDropDown from '../../ui/DropDown/LocationDropDown';
import { AddDeliveryHoliday, UpdateDeliveryHoliday } from '../../../axios/api';
import { dateWithoutTime } from '../../../utils/helperDateTime';
import LoaderBtn from '../../ui/LoaderBtn';
import PopupLayout from '../../ui/PopupLayout';
import InputLayout from '../../ui/InputLayout';

const DeliveryHolidayModal = ({ open, onClose, title, btn, data, fetchDetails }) => {

    const showSnackbar = useSnackbar();
    const [submitLoader, setSubmitLoader] = useState(false)

    const [holidayData, setHolidayData] = useState({
        deliveryLocation_Id: data?.deliveryLocation_Id || "",
        deliveryLocationName: data?.deliveryLocation || "",
        holiday: data?.holiday || "",
        description: data?.description || ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setHolidayData({ ...holidayData, [name]: value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setSubmitLoader(true)
        if (btn === "Add") {
            // To Add the Delivery Holiday
            try {
                const response = await AddDeliveryHoliday(holidayData)
                if (response.status === 200) {
                    showSnackbar('Delivery Loction Holiday Added Successfully.', 'success');
                    onClose()
                    fetchDetails("")
                    setSubmitLoader(false)
                }
            } catch (error) {
                console.error("Error:", error);
                setSubmitLoader(false)
                showSnackbar(error.response?.data?.message || 'An error occurred while Adding the Holiday.', 'error');

            }
        } else if (btn === "Update") {
            try {
                const response = await UpdateDeliveryHoliday({ ...holidayData, id: data?.id })
                if (response.status === 200) {
                    showSnackbar('Delivery Loction Holiday Updated Successfully.', 'success');
                    onClose()
                    fetchDetails("")
                    setSubmitLoader(false)
                }
            } catch (error) {
                console.error("Error:", error);
                showSnackbar(error.response?.data?.message || 'An error occurred while Updating the Holiday.', 'error');
                setSubmitLoader(false)
            }
        }

    }

    return (
        <>
            <PopupLayout open={open} onClose={onClose} title={title} handleSubmit={handleSubmit}>

                {/* <--------------------- Location Name ---------------------> */}
                <LocationDropDown size={4} title={"Delivery Location"} dropDownValue={holidayData.deliveryLocationName} dropDownId={holidayData.deliveryLocation_Id} dropDownState={holidayData} setDropDownState={setHolidayData} required={true} />


                {/* <--------------------- Holiday Name ---------------------> */}
                <InputLayout size={4} title="Holiday Name" type="text" name="description" value={holidayData?.description} onChange={handleChange} placeholder="Enter Holiday Name" required={true} />


                {/* <--------------------- Hours of Operation ---------------------> */}
                <InputLayout size={4} title="Date" type="date" name="holiday" value={dateWithoutTime(holidayData?.holiday)} onChange={handleChange} required={true} />


                <div className='d-flex justify-content-end gap-4'>
                    <button className='cancel-btn' onClick={onClose}>Cancel</button>
                    <LoaderBtn loader={submitLoader} title={btn} />
                </div>
            </PopupLayout>
        </>
    )
}

export default DeliveryHolidayModal