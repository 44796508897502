import React from 'react'
import { Table, TableHead, TableRow, TableCell, TableSortLabel, TableBody } from "@mui/material"
import AppointtableRow from './AppointtableRow'
import Data from "./appointmentDetails.json"
import { tableHead, tableRow } from '../../../utils/helperInline'

const AppointmentTable = ({ data, sortBy, sortOrder, page, rowsPerPage, handleSort }) => {


    const tableHeading = [
        { title: "CONTAINER #", sort: "containerNumber" },
        { title: "STATUS", sort: "containerStatus" },
        { title: "TIME SLOT", sort: "" },
        { title: "APPT NUMBER", sort: "appointmentNumber" },
        { title: "APPOINTMENT DATE", sort: "appointmentDate" },
        { title: "APPOINTMENT SYSTEM", sort: "AppointmentDoneBySystem" },
        { title: "TERMINAL", sort: "terminalName" },
        { title: "DELIVERY LOCATION", sort: "deliveryLocation" },
        { title: "LFD", sort: "lfd" },
    ]

    return (
        <>
            <Table>
                <TableHead sx={tableHead}>
                    <TableRow>
                        {
                            tableHeading.map((item) => (
                                <TableCell>
                                    {
                                        item.title === "TIME SLOT" ? item.title : <TableSortLabel active={sortBy === item.sort} direction={sortOrder} onClick={() => handleSort(item.sort)}>
                                            {item.title}
                                        </TableSortLabel>
                                    }
                                </TableCell>
                            ))
                        }
                        <TableCell>
                            ACTION
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className="tableBody" sx={tableRow}>
                    {
                        data.length !== 0 ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data) => (
                            <AppointtableRow data={data} />
                        )) : <>No Record Found</>
                    }
                </TableBody>
            </Table>
        </>
    )
}

export default AppointmentTable