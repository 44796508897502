import React, { useState } from 'react'
import { useSnackbar } from '../../../context/SnackbarContext';
import LoaderBtn from '../../ui/LoaderBtn';
import PopupLayout from '../../ui/PopupLayout';
import { AddProfile, UpdateProfile } from '../../../axios/api';
import InputLayout from '../../ui/InputLayout';

const SuperProfileModal = ({ open, onClose, title, data, btn, fetchDetails }) => {

    const showSnackbar = useSnackbar();
    const [submitLoader, setSubmitLoader] = useState(false)

    const [profileData, setProfileData] = useState({
        name: data?.name || "",
        email: data?.email || "",
        mobile: data?.mobile || ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setProfileData({ ...profileData, [name]: value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setSubmitLoader(true)
        if (btn === "Add") {

            // To Add the Delivery Location
            try {
                const response = await AddProfile(profileData)
                if (response.status === 200) {
                    showSnackbar('Profile Added Successfully.', 'success');
                    onClose()
                    setSubmitLoader(false)
                    fetchDetails("")
                }
            } catch (error) {
                console.error("Error:", error);
                showSnackbar(error.response?.data?.message || 'An error occurred while Adding the Profile.', 'error');
            }
        } else if (btn === "Update") {

            // To Update the Terminal Record
            try {
                const response = await UpdateProfile({ ...profileData, id: data.id })
                if (response.status === 200) {
                    showSnackbar('Profile Updated Successfully.', 'success');
                    onClose()
                    setSubmitLoader(false)
                    fetchDetails("")
                }
            } catch (error) {
                console.error("Error:", error);
                showSnackbar(error.response?.data?.message || 'An error occurred while Updating the Profile.', 'error');
                setSubmitLoader(false)
            }
        }
    }


    return (
        <>
            <PopupLayout open={open} onClose={onClose} title={title} handleSubmit={handleSubmit}>

                {/* <--------------------- Profile Name ---------------------> */}
                <InputLayout size={12} title="Profile Name" type="text" name="name" value={profileData?.name} onChange={handleChange} placeholder="Enter Name" required={true} />


                {/* <--------------------- Email ---------------------> */}
                <InputLayout size={6} title="Email" type="email" name="email" value={profileData?.email} onChange={handleChange} placeholder="example@example.com" pattern="^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$" required={true} />


                {/* <--------------------- Mobile ---------------------> */}
                <InputLayout size={6} title="Mobile" type="tel" name="mobile" value={profileData?.mobile} onChange={handleChange} placeholder="+1 888-878-9080" pattern="^\+\d{1,4} \d{3}-\d{3}-\d{4}$" required={true} message={"+1 888-878-9080"} />


                <div className='d-flex justify-content-end gap-4'>
                    <button className='cancel-btn' onClick={onClose}>Cancel</button>
                    <LoaderBtn loader={submitLoader} title={btn} />
                </div>
            </PopupLayout>
        </>
    )
}

export default SuperProfileModal