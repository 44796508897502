import React, { useState } from 'react'
import { TableRow, TableCell, Button } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SuperProfileModal from '../../../popup/TruckerPopup/SuperProfileModal';
import DeleteProfileModal from '../../../popup/TruckerPopup/DeleteProfileModal';
import { formatDate } from '../../../../utils/helperDateTime';
import { Link, useNavigate } from 'react-router-dom';
import { encodeURL } from '../../../../utils/helperPages';

const ProfiletableRow = ({ data, handleSort }) => {

    const navigate = useNavigate()

    const [isEditActive, setIsEditActive] = useState(false)
    const [isDeleteActive, setIsDeleteActive] = useState(false)


    const { id, name, email, mobile, created_At, last_Updated_At } = data


    const handleClick = (e) => {
        const clickedElement = e.target;
        if (!clickedElement.classList.contains('row-action') && !clickedElement.parentNode.classList.contains('row-action')) {
            navigate(`/operation/trucker-profile/${encodeURL(name)}`)
        }
    }

    return (
        <>
            <TableRow onClick={handleClick} className="tableRow coursor-ponter" key={name}>
                <TableCell className="containerId">
                    {name || "--"}
                </TableCell>
                <TableCell>{email || "--"}</TableCell>
                <TableCell >{mobile || "--"}</TableCell>
                <TableCell >{formatDate(created_At) || "--"}</TableCell>
                <TableCell >{formatDate(last_Updated_At) || "--"}</TableCell>
                <TableCell>
                    <div className='dot-box'>
                        <EditIcon onClick={() => setIsEditActive(true)} className='me-3 row-action' />
                        {/* <DeleteOutlinedIcon onClick={() => setIsDeleteActive(true)} className='row-action' /> */}
                    </div>
                </TableCell>
            </TableRow>

            {
                isEditActive && <SuperProfileModal open={isEditActive} onClose={() => setIsEditActive(false)} title={"Edit Trucker Profile"} data={data} btn={"Update"} fetchDetails={handleSort} />
            }

            {
                isDeleteActive && <DeleteProfileModal open={isDeleteActive} onClose={() => setIsDeleteActive(false)} title={"Delete Profile"} />
            }
        </>
    )
}

export default ProfiletableRow