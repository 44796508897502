import Navbar from "./Navbar";
import logo from "../../../src/assets/logo.png";
import { Link } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import LogoutButton from "../../logout/LogoutButton";
import axios from "axios";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useMsal } from "@azure/msal-react";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import NotificationsPopup from "../Notification/NotificationPopup";
import { useAuth } from "../../context/AuthContext";
import { UserDetails } from "../../axios/api";

const Header = () => {
  const { instance, accounts, inProgress } = useMsal();

  const userMenuRef = useRef(null);
  const userIconRef = useRef(null);
  const [activeUserMenu, setActiveUserMenu] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const navigate = useNavigate();
  // const userEmail = localStorage.getItem('user');
  // const userName = localStorage.getItem('userName');

  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [organization, setOrganization] = useState("");


  const { updateAccessToken, updateUserDetails, updateNavigation, updateUserId, updateUserRole, updateUserName } = useAuth(); // Use the AuthContext functions

  useEffect(() => {
    if (accounts.length > 0 && inProgress === "none") {
      instance
        .acquireTokenSilent({
          account: accounts[0],
          scopes: ["user.read"],
        })
        .then((response) => {
          setAccessToken(response.accessToken);
          // localStorage.setItem("accessToken", response.accessToken);
          updateAccessToken(response.accessToken);
          fetchUserInfo(response.accessToken)
        })
        .catch((error) => {
          console.error(error);
        });
    }

  }, [accounts, inProgress, instance]);

  // useEffect(()=>{
  //   if(localStorage.getItem('accessToken')){
  //     fetchUserInfo(accessToken);

  //   }
  // },[accessToken])

  const fetchUserInfo = async () => {
    try {
      const response = await UserDetails()
      setUserEmail(response.data.userEmailId);
      setUserName(response.data.userName);
      setOrganization(response.data.orgName);

      updateUserId(response.data.userEmailId);
      updateUserName(response.data.userName);
      updateUserRole(response.data.roles[0]);
      // updateUserRole("Super Admin");
      const hasMissingRoles = response.data.roles[0];
      !hasMissingRoles ? (
        <Navigate to="/unauthorized" />
      ) : (
        <Navigate to="/" />
      );
    } catch (error) {
      console.error("Error fetching user info: ", error);
      navigate("/unauthorized");
    }
  };

  const handleUserMenuClick = () => {
    setActiveUserMenu(!activeUserMenu);
  };

  const handleUserMenuItemClick = () => {
    setActiveUserMenu(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleDocumentClick = (e) => {
    if (
      userMenuRef.current &&
      !userMenuRef.current.contains(e.target) &&
      userIconRef.current &&
      !userIconRef.current.contains(e.target)
    ) {
      setActiveUserMenu(false);
    }
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 300,
      fontSize: theme.typography.pxToRem(11),
      border: "1px solid #dadde9",
    },
  }));

  return (
    <header>
      <div className="nav-area">
        <Link to="/">
          <div className="logo">
            <img src={logo} height={40} alt="Logo" />
          </div>
        </Link>
        <Navbar />

        <div className="right-icons">
          {/* <div className="icon">📩</div> */}
          {/* <NotificationsPopup /> */}
          {/* <div className="icon">🔔</div> */}
          <div className="user-profile">
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    <span className="user-email">{userEmail}</span>
                    <br />
                    <span className="user-email">
                      {organization
                        ? `Org Name: ${organization}`
                        : "Org Name: --"}
                    </span>
                  </Typography>
                </React.Fragment>
              }
            >
              <span>{userEmail && userEmail.slice(0, 10) + "..."}</span>
            </HtmlTooltip>
          </div>

          <div
            ref={userIconRef}
            className="icon user-icon"
            onClick={handleUserMenuClick}
          >
            👤
          </div>

          <div
            ref={userMenuRef}
            className={`user-menu ${activeUserMenu ? "active" : ""}`}
            style={{
              display: activeUserMenu ? "block" : "none",
            }}
          >
            <div className="user-name">
              <span>{userName && userName}</span>
            </div>
            <div className="user-menu-item" onClick={handleUserMenuItemClick}>
              <button>User Settings</button>
              {/* <Link>User Settings</Link> */}
            </div>
            <div className="user-menu-item" onClick={handleUserMenuItemClick}>
              <LogoutButton />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
