import config from "./config";

export const msalConfig = {
  auth: {
    clientId: "8e6a849b-fa0b-43f6-8615-cb9e54a8af5a",
    authority: "https://login.microsoftonline.com/52925b26-9cba-4c12-87e6-23007e20f9fa",

    // Talk
    // clientId: "27bffca3-d8f0-44ed-ac7d-1979a84754c1",
    // authority: "https://login.microsoftonline.com/c2486751-438c-4293-8e06-063e4e40a672",
    // redirectUri: "https://drayscheduler-ui-dev.azurewebsites.net/",
    redirectUri: process.env.REACT_APP_API_URL,
    postLogoutRedirectUri: process.env.REACT_APP_API_URL,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};
