import { string } from "yup";

export const formatDate = (inputDate) => {
    // Create a Date object from the input date
    const date = new Date(inputDate);

    // Extract day, month, and year components
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based, so add 1
    const year = date.getFullYear();

    // Pad day and month with leading zeros if needed
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    // Construct the formatted date string in MM/DD/YYYY format
    const formattedDate = `${formattedMonth}/${formattedDay}/${year}`;

    return formattedDate;
}


export const dateChecker = (date, time) => {
    const currentDate = new Date()
    const targetDate = new Date(date)

    const check = (targetDate > currentDate) ? true : false

    return check
}


export const dateWithoutTime = (dateTimeString) => {
    if (!dateTimeString) {
        return '';
    }

    const dateWithTime = new Date(dateTimeString);
    const year = dateWithTime.getFullYear();
    const month = String(dateWithTime.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed, so add 1 and pad to 2 digits
    const day = String(dateWithTime.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}


export const convertTimeRange = (input) => {
    const [start, end] = input.split('-');

    const formattedStart = padWithZero(start) + ":00";
    const formattedEnd = padWithZero(end) + ":00";

    return `${formattedStart} - ${formattedEnd}`;
}

export const convertInTimeRange = (input) => {
    return padWithZero(String(input)) + ":00"
}

function padWithZero(value) {
    return value.length === 1 ? `0${value}` : value;
}

