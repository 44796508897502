import axios from "axios";

const API = axios.create({ baseURL: "https://drayscheduler-apim.azure-api.net/api/" })

API.interceptors.request.use((req) => {
    // Interceptor is use the add value in the Header globally in the API call
    req.headers.Authorization = `Bearer ${localStorage.getItem("accessToken")}`
    return req;
})


// Normal - For All
export const UNINav = () => API.get('Navigation/v1/Details')
export const UserDetails = () => API.get('User/v1/Details')
export const UserRole = () => API.get('User/v1/Roles')

// Notification - For All - not working
export const NotificationReadCount = () => API.get('AdminPortal/v1/SubscriptionNotificationsUnreadCount')
export const NotificationPage = (page, pageSize) => API.get(`AdminPortal/v1/SubscriptionNotifications?PageNumber=${page}&PageSize=${pageSize}`)
export const NotificationsReadStatus = (data) => API.put('AdminPortal/v1/SubscriptionNotificationsReadStatus', data)
export const NotificationsClearAll = () => API.put('AdminPortal/v1/SubscriptionNotificationsClearAll', null)

// Appointment - For All
export const AppointmentsData = (params) => API.get(`Appointment/v1/GetAppointments?${params.join("&")}`)
export const AppointmentReschedule = (id) => API.put(`Appointment/v1/Reschedule?AppointmentId=${id}`)
export const AppointmentCancel = (id) => API.post(`Appointment/v1/Cancel?AppointmentId=${id}`)
export const AppointSlot = () => API.get('Appointment/v1/GetTimeSlots')
export const TerminalLists = () => API.get('Appointment/v1/GetTerminals')


// Trucker Profile - Admin and Super Admin
export const TruckerProfileData = (params) => API.get(`TruckerTerminals/v1/GetTruckerTerminals?${params.join("&")}`)
export const UpdateTruckerTerminalRecord = (data) => API.put('TruckerTerminals/v1/EditTruckerTerminal', data)

export const AddTerminalRecord = (data) => API.post('TruckerTerminals/v1/AddTerminalRecord', data)
export const TruckerTerminals = () => API.get('')
export const TruckerLists = () => API.get('TruckerProfile/v1/GetTruckerslist')


// Trucker Profile - Super Admin
export const AddTruckerRecord = (data) => API.post('TruckerTerminals/v1/AddTruckerTerminal', data)
export const AssignTerminalRecord = (data) => API.post('TruckerTerminals/v1/AssignTerminal', data)


// List of Profile - Super Admin
export const ListOfProfileData = (params) => API.get(`TruckerProfile/v1/GetTruckerProfiles?${params.join("&")}`)
export const AddProfile = (data) => API.post('TruckerProfile/v1/CreateTruckerProfile', data)
export const UpdateProfile = (data) => API.put('TruckerProfile/v1/EditProfile', data)
export const ProfileList = () => API.get('')
export const TruckerProfileDetials = (params) => API.get(`TruckerProfile/v1/GetTruckerProfile`)
export const TruckerClientList = () => API.get('DeliveryLocationProfile/v1/GetTruckerClientList')

// Delivery Location - Admin and Super Admin
export const DeliveryLocationData = (params) => API.get(`DeliveryLocationProfile/v1/GetDeliveryLocations?${params.join("&")}`)
export const UpdateDeliveryProfile = (data) => API.put('DeliveryLocationProfile/v1/UpdateDeliveryLocation', data)
export const DeliveryLocationLists = () => API.get('DeliveryLocationProfile/v1/GetDeliveryLocationsList')
export const DeliveryClientList = () => API.get('')
export const DeliveryTrucker = () => API.get('')


// Delivery Location - Super Admin
export const AddDeliveryProfile = (data) => API.post('DeliveryLocationProfile/v1/AddDeliveryLocation', data)
export const AddNewLocationName = (data) => API.post('DeliveryLocationProfile/v1/AddNewDeliveryLocation', data)

// Terminal Info
export const TerminalInfoData = (params) => API.get(`Terminals/v1/Terminals?${params.join("&")}`)
export const AddTerminalData = (data) => API.post('Terminals/v1/CreateTerminal', data)
export const UpdateTerminalData = (data) => API.put('Terminals/v1/UpdateTerminal', data)
export const AddTerminalName = (data) => API.post('Terminals/v1/AddTerminal', data)
export const TerminalNameList = () => API.get('')
export const TerminalStateList = () => API.get('')


// Holiday List - Delivery Location
export const DeliveryLocHoliday = (params) => API.get(`DeliveryLocationHolidays/v1/GetDeliveryLocationHolidays?${params.join("&")}`)
export const AddDeliveryHoliday = (data) => API.post('DeliveryLocationHolidays/v1/CreateDeliveryLocationHolidays', data)
export const UpdateDeliveryHoliday = (data) => API.put('DeliveryLocationHolidays/v1/UpdateDeliveryLocationHolidays', data)


// Holiday List - Terminal
export const TerminalHoliday = (params) => API.get(`TerminalHolidays/v1/GetTerminalHolidays?${params.join("&")}`)
export const AddTerminalHoliday = (data) => API.post('TerminalHolidays/v1/CreateTerminalHolidays', data)
export const UpdateTerminalHoliday = (data) => API.put('TerminalHolidays/v1/UpdateTerminalHolidays', data)



// -----------------------------  Admin ------------------------------ //


// Trucker Profile - Admin
// export const TruckerProfileData = (params) => API.get(``)
// export const UpdateTerminalRecord = (data) => API.post('', data)
// export const AddTerminalRecord = (data) => API.post('', data)
// export const UpdateProfile = (data) => API.post('', data)


// Delivery Location Profile - Admin
// export const DeliveryLocationData = (params) => API.get(`DeliveryLocationProfile/v1/GetDeliveryLocations?${params.join("&")}`)
// export const AddDeliveryRecord = (data) => API.post('', data)
// export const UpdateDeliveryRecord = (data) => API.post('', data)


// ----------------------------- Super Admin ------------------------------ //


// Trucker Profile - Super Admin
// export const TruckerProfileDataSuper = () => API.get('')
// export const AddTruckerRecord = (data) => API.post('', data)
// export const UpdateTruckerRecord = (data) => API.post('', data)



// Delivery Location Profile - Super Admin
// export const DeliveryLocationDataSuper = () => API.get('')
// export const AddDeliveryRecordSuper = (data) => API.post('', data)
// export const UpdateDeliveryRecordSuper = (data) => API.post('', data)





