import React, { useEffect, useContext } from "react";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { InteractionType } from "@azure/msal-browser";
import AppReadyContext from "../context/AppReadyContext";
import { UNINav } from "../axios/api";

function TokenValidityChecker() {
    const { instance, accounts } = useMsal();
    const { appReady } = useContext(AppReadyContext);

    const checkTokenValidity = async () => {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
            if (accounts.length > 0) {
                try {
                    const response = await UNINav()
                    if (response.status === 200) {
                        const decodedToken = jwtDecode(accessToken);
                        const currentTime = Math.floor(Date.now() / 1000); // Convert to seconds
                        const validityDuration = decodedToken.exp - currentTime;

                        if (validityDuration < 3600) {
                            console.log("Token validity is less than 1 hour, refreshing...");
                            refreshToken();
                        } else {
                            // console.log("Token is valid for a long time")
                        }
                    } else if (response.status === 403) {
                        console.log("Token is invalid, refreshing...");
                        refreshToken();
                    } else {
                        console.log("Unknown token status, logging out...");
                        instance.logout();
                    }
                } catch (error) {
                    console.error("Error checking token validity:", error);
                    refreshToken();
                }
            }
        }
    };

    const refreshToken = async () => {
        try {
            console.log('setting refresh token')
            const response = await instance.acquireTokenSilent({
                account: accounts[0],
                scopes: ["user.read"],
                forceRefresh: true, // Set this to "true" to force a token refresh
            });
            console.log("Refresh the token")

            // console.log("Refresh Token generated");
            console.log("Access Token: ", response.accessToken);
            localStorage.setItem("accessToken", response.accessToken);

            // Refresh the page to reflect the new token
            window.location.reload();
        } catch (error) {
            console.error("Token refresh error:", error);

            instance.logout();
        }
    };

    useEffect(() => {
        const handleRedirect = () => {
            checkTokenValidity();
        };

        instance.addEventCallback(handleRedirect);

        // Check token validity when the page loads
        checkTokenValidity();

        // Set up an interval to check token validity every 10 minutes (600,000 milliseconds)
        const intervalId = setInterval(() => {
            checkTokenValidity();
        }, 600000);

        return () => {
            instance.removeEventCallback(handleRedirect);
            clearInterval(intervalId);
        };
    }, [instance, accounts, appReady]);

    return null;
}

export default TokenValidityChecker;
