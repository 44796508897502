import React from 'react'

const LoaderBtn = ({ loader, title }) => {
    return (
        <>
            <button className='submit-btn' type='submit'>
                {
                    loader ? <div class="spinner-border spinner-size" role="status" />
                        : <>{title}</>
                }
            </button>
        </>
    )
}

export default LoaderBtn