import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <span className="footer-text">
        <span className="powered">Powered by</span>{" "}
        <span className="highlighted-dray">DRAY</span>{" "}
        <span className="highlighted-data">DATA</span>
      </span>
    </div>
  );
};

export default Footer;
