import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { AppointmentReschedule } from '../../../axios/api';
import { useSnackbar } from '../../../context/SnackbarContext';
import PopupLayout from '../../ui/PopupLayout';
import LoaderBtn from '../../ui/LoaderBtn';

const ReSheduleAppointment = ({ open, onClose, title, data }) => {

    const [submitLoader, setSubmitLoader] = useState(false)
    const showSnackbar = useSnackbar();

    const handleSubmit = async (e) => {
        e.preventDefault()
        onClose()
        showSnackbar('Feature in Progress.', 'error');
        // setSubmitLoader(true)
        // try {
        //     const response = await AppointmentReschedule(data?.id)
        //     if (response.status === 200) {
        //         showSnackbar('Appointment Rescedule is Success.', 'success');
        //         onClose()
        //         setSubmitLoader(false)
        //     }
        // } catch (error) {
        //     console.error("Error:", error);
        //     showSnackbar(error.response?.data?.message || 'An error occurred while Rescheduleing the Appointment.', 'error');
        // }
    }

    return (
        <>
            <PopupLayout open={open} onClose={onClose} title={title} handleSubmit={handleSubmit}>
                <Container fluid className="p-0">
                    <Row>
                        <Col lg={12}>
                            <div className="input-container">
                                <p>Are you sure you want to Reschedule your appointment.</p>
                            </div>
                        </Col>
                    </Row>

                    <div className='d-flex justify-content-end gap-4'>
                        <button className='cancel-btn' onClick={onClose}>Cancel</button>
                        <LoaderBtn loader={submitLoader} title={"Reschedule"} />
                    </div>
                </Container>
            </PopupLayout>
        </>
    )
}

export default ReSheduleAppointment