import React, { useState } from 'react'
import { TableRow, TableCell, Button } from "@mui/material"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReSheduleAppointment from '../../popup/AppointmentPopup/ReSheduleAppointment';
import CancelAppointModal from '../../popup/AppointmentPopup/CancelAppointModal';
import { dateChecker, formatDate } from '../../../utils/helperDateTime';

const AppointtableRow = ({ data }) => {

    const [isRescheduleActive, setIsRescheduleActive] = useState(false)
    const [isCancelActive, setIsCancelActive] = useState(false)

    const { containerNumber, containerStatus, appointmentSlots, appointmentDate, appointmentNumber, terminalName, deliveryLocation, lfd, appointmentDoneBySystem } = data

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const checkStatus = (status) => {
        switch (status) {
            case "Scheduled": return "status-confirmed"
            case "Queued": return "status-queued"
            case "In Queue": return "status-queued"
            case "Tentative": return "status-tentive"
            case "Cancelled": return "status-cancelled"
            case "Scheduled after LFD": return "status-confirmed"
        }
    }

    const handleMenu = (type) => {
        if (type === "Reshedule") {
            setIsRescheduleActive(true)
            setAnchorEl(null)
        } else if (type === "Cancel") {
            setAnchorEl(null)
            setIsCancelActive(true)
        }
    }

    function isValidTimeRange(timeRange) {
        // Check if the timeRange is undefined or empty
        if (!timeRange || timeRange.trim() === "") {
            return false;
        }

        // Split the time range string into start and end times
        const [startTime, endTime] = timeRange.split(" - ");

        // Get the current time as a Date object
        const currentTime = new Date();

        // Parse the start and end times as Date objects
        const startDateTime = new Date(currentTime.toDateString() + " " + startTime);
        const endDateTime = new Date(currentTime.toDateString() + " " + endTime);

        // Check if the current time is within the specified range
        const checkRange = currentTime >= startDateTime && currentTime <= endDateTime;

        return checkRange
    }



    return (
        <>
            <TableRow className="tableRow" key={containerNumber}>
                <TableCell className="containerId ">
                    {containerNumber || "--"}
                </TableCell>
                <TableCell>
                    <div
                        className={`btn text-bg summary-status ${checkStatus(containerStatus)} btn-sm position-relative`}
                    >
                        {containerStatus || "--"}
                    </div>
                </TableCell>
                <TableCell>{appointmentSlots || "--"}</TableCell>
                <TableCell>{appointmentNumber || "--"}</TableCell>
                <TableCell>{appointmentDate ? formatDate(appointmentDate) : "--"}</TableCell>
                <TableCell>{appointmentDoneBySystem || "--"}</TableCell>
                <TableCell>{terminalName || "--"}</TableCell>
                <TableCell>{deliveryLocation || "--"}</TableCell>
                <TableCell>{formatDate(lfd) || "--"} </TableCell>
                <TableCell>
                    <Button
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                        className=''
                    >
                        <div className='dot-box'>
                            <MoreVertIcon />
                        </div>
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => setAnchorEl(null)}
                        MenuListProps={{
                            "aria-labelledby": "basic-button"
                        }}
                    >
                        <MenuItem onClick={() => handleMenu("Reshedule")}>Reshedule</MenuItem>
                        <MenuItem onClick={() => handleMenu("Cancel")}>Cancel</MenuItem>
                    </Menu>
                </TableCell>
                {/* <TableCell>
                    {
                        (appointmentSlots && appointmentDate && containerStatus !== "Cancelled") && <>
                            {
                                <>
                                    <Button
                                        id="basic-button"
                                        aria-controls={open ? "basic-menu" : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? "true" : undefined}
                                        onClick={(e) => setAnchorEl(e.currentTarget)}
                                        className=''
                                    >
                                        <div className='dot-box'>
                                            <MoreVertIcon />
                                        </div>
                                    </Button>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={() => setAnchorEl(null)}
                                        MenuListProps={{
                                            "aria-labelledby": "basic-button"
                                        }}
                                    >
                                        <MenuItem onClick={() => handleMenu("Reshedule")}>Reshedule</MenuItem>
                                        {
                                            (dateChecker(formatDate(appointmentDate)) && !isValidTimeRange(appointmentSlots)) && <>
                                                <MenuItem onClick={() => handleMenu("Cancel")}>Cancel</MenuItem>
                                            </>
                                        }
                                    </Menu>
                                </>
                            }
                        </>
                    }
                </TableCell> */}

            </TableRow>

            {
                isRescheduleActive && <ReSheduleAppointment open={isRescheduleActive} onClose={() => setIsRescheduleActive(false)} title={"Reschedule Appointment"} data={data} />
            }

            {
                isCancelActive && <CancelAppointModal open={isCancelActive} onClose={() => setIsCancelActive(false)} title={"Cancel Appointment"} data={data} />
            }
        </>
    )
}

export default AppointtableRow