import React from 'react'
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material"

const ThemeBtn = ({ name, handleClick, disabled = false, width = "auto" }) => {

    const ColorButton = styled(Button)(({ theme }) => ({
        width: width,
        color: "white",
        size: "small",
        borderRadius: "5px",
        height: "40px",
        backgroundColor: "#1B4E6A",
        fontSize: "14px",
        "&:hover": {
            backgroundColor: "#163E55",
        },
        "&:disabled": {
            backgroundColor: "#CCCCCC", // Button color when disabled
            opacity: 0.7, // Adjust the opacity as desired
            cursor: "not-allowed", // Show 'not-allowed' cursor when disabled
        },
        textTransform: "none",
    }));

    return (
        <>
            <ColorButton
                className="filter-button"
                variant="contained"
                onClick={handleClick}
                disabled={disabled}
            >
                {name}
            </ColorButton>
        </>
    )
}

export default ThemeBtn