import React, { useContext } from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SuperTruckerProfile from './TruckerProfileSuper/SuperTruckerProfile';
import ProfileList from './ProfileList/ProfileList';
import SuperDeliveryLoc from './DeliveryLocationSuper/SuperDeliveryLoc';
import TerminalInfoList from './TerminalInfo/TerminalInfoList';
import { useAuth } from '../../context/AuthContext';
import HolidayList from './HolidayList/HolidayList';
import DeliveryHolidayList from './DeliveryLocationSuper/DeliveryHolidayList/DeliveryHolidayList';
import TerminalHolidayList from './TerminalInfo/TerminalHolidayList/TerminalHolidayList';
import ReportContext from '../../context/ReportContext';


const SuperAdminRoute = () => {

    const { userRole } = useAuth()

    const { setReportType } = useContext(ReportContext);
    setReportType("operationsIcon");

    return (
        <Routes >
            <Route path="/trucker-profile" element={<ProfileList />} />
            <Route path="/trucker-profile/:id" element={<SuperTruckerProfile />} />
            <Route path="/delivery-location-profile" element={<SuperDeliveryLoc />} />
            <Route path="/terminal-info" element={<TerminalInfoList />} />

            {
                userRole === "Super Admin" && <>
                    <Route path="/delivery-holiday" element={<DeliveryHolidayList />} />
                    <Route path="/terminal-holiday" element={<TerminalHolidayList />} />
                </>
            }
        </Routes>
    )
}

export default SuperAdminRoute