import React from 'react'
import Select from 'react-select';
import { labelConvert } from '../../../utils/helperPages'

const PortDropDown = ({ dropDownValue, dropDownState, setDropDownState }) => {

    const list = ["Los Angeles", "Long Beach"]

    const handleChange = (e) => {
        if (e === null) {
            setDropDownState({ ...dropDownState, city: "" })
        } else {
            setDropDownState({ ...dropDownState, city: e.value })
        }
    }

    return (
        <>
            <Select
                className='select-custom'
                isClearable={true}
                value={!dropDownValue ? null : { label: dropDownValue, value: dropDownValue }}
                options={labelConvert(list)}
                onChange={handleChange}
                required
            />
        </>
    )
}

export default PortDropDown