const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 82;
export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const tableHead = {
    th: {
        fontWeight: 600,
        height: "20px",
        fontSize: "12px",
        lineHeight: "12px",
        padding: "0 20px",
    },
    tr: { height: "40px" },
}

export const tableRow = {
    td: {
        fontWeight: 400,
        height: "24px",
        fontSize: "12px",
        lineHeight: "24px",
        padding: "0 20px",
    },
    tr: { height: "40px" },
}

export const PickerSx = {
    "& .MuiInputBase-input": {
        // Your custom CSS properties for the MuiInputBase-input class
        padding: "10px"
        // Add any other styles you need here
    }
} 