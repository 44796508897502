import React from 'react'
import { Table, TableHead, TableRow, TableCell, TableSortLabel, TableBody } from "@mui/material"
import ProfiletableRow from './ProfiletableRow'
import { tableHead, tableRow } from '../../../../utils/helperInline'

const ProfileTable = ({ data, sortBy, sortOrder, page, rowsPerPage, handleSort }) => {



  const tableHeading = [
    { title: "PROFILE NAME", sort: "name" },
    { title: "EMAIL", sort: "email" },
    { title: "MOBILE", sort: "mobile" },
    { title: "CREATED DATE", sort: "created_At" },
    { title: "UPDATED DATE", sort: "last_Updated_At" },
  ]

  return (
    <>
      <Table>
        <TableHead sx={tableHead}>
          <TableRow>
            {
              tableHeading.map((item) => (
                <TableCell>
                  <TableSortLabel
                    active={sortBy === item.sort}
                    direction={sortOrder}
                    onClick={() => handleSort(item.sort)}
                  >
                    {item.title}
                  </TableSortLabel>
                </TableCell>
              ))
            }
            <TableCell>ACTION</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="tableBody" sx={tableRow}>
          {
            data.length !== 0 ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data) => (
              <ProfiletableRow data={data} handleSort={handleSort} />
            )) : <>No Record Found</>
          }
        </TableBody>
      </Table>
    </>
  )
}

export default ProfileTable