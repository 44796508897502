import React from 'react'
import { CircularProgress } from "@mui/material"

const Loader = () => {
    return (
        <div className="loading-container">
            <div>Loading Data</div>
            <CircularProgress color="primary" />
        </div>
    )
}

export default Loader