import React, { useState } from 'react'
import TruckerDropDown from '../../ui/DropDown/TruckerDropDown';
import TerminalDropDown from '../../ui/DropDown/TerminalDropDown';
import DaysDropDown from '../../ui/DropDown/DaysDropDown';
import { useSnackbar } from '../../../context/SnackbarContext';
import { AssignTerminalRecord, UpdateTruckerTerminalRecord } from '../../../axios/api';
import { useDropDown } from '../../../context/DropDownContext';
import { useParams } from 'react-router-dom';
import { decodeURL } from '../../../utils/helperPages';
import LoaderBtn from '../../ui/LoaderBtn';
import PopupLayout from '../../ui/PopupLayout';
import InputLayout from '../../ui/InputLayout';
import CutOfftimrDropDown from '../../ui/DropDown/CutOfftimrDropDown';

const SuperTruckerModal = ({ open, onClose, title, btn, data, fetchDetails }) => {

    const { id } = useParams()
    const showSnackbar = useSnackbar();

    const [submitLoader, setSubmitLoader] = useState(false)

    const { truckerList } = useDropDown()

    const getTruckerId = () => {
        return truckerList.find((e) => e.name === decodeURL(id)).id
    }

    const [recordData, setRecordData] = useState({
        trucker_Id: getTruckerId() || "",
        truckerName: decodeURL(id) || "",
        terminal_Id: data?.terminal_Id || "",
        terminalName: data?.terminalName || "",
        capacity_Per_Day: data?.capacity_Per_Day || "",
        day: data?.day || "",
        operating_Slot: data?.operating_Slot || "",
        cutoff_Time: data?.cutoff_Time || "",
        scheduler_Wait_Window: !data?.scheduler_Wait_Window ? 0 : data?.scheduler_Wait_Window || ""
    })
    const [displayValue, setDisplayValue] = useState(!data?.scheduler_Wait_Window ? 0 : data?.scheduler_Wait_Window);

    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === "capacity_Per_Day" || name === "cutoff_Time") {
            setRecordData({ ...recordData, [name]: parseInt(value) })
        } else if (name === "scheduler_Wait_Window") {
            const newValue = value ? parseInt(value) : 0;
            setRecordData((prevData) => ({ ...prevData, [name]: newValue }));
            setDisplayValue(newValue === 0 ? 0 : newValue.toString());
        } else {
            setRecordData({ ...recordData, [name]: value })
        }
    }

    console.log(recordData)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setSubmitLoader(true)
        if (btn === "Add") {
            // To Add the Terminal Record
            try {
                const response = await AssignTerminalRecord(recordData)
                if (response.status === 200) {
                    showSnackbar('Terminal Record Added Successfully.', 'success');
                    onClose()
                    fetchDetails("")
                    setSubmitLoader(false)
                }
            } catch (error) {
                console.error("Error:", error);
                showSnackbar(error.response?.data?.message || 'An error occurred while Adding the Terminal Record.', 'error');
                setSubmitLoader(false)
            }
        } else if (btn === "Update") {

            // To Update the Terminal Record
            try {
                const response = await UpdateTruckerTerminalRecord({ ...recordData, truckerTerminalId: data?.truckerTerminalId || "", truckerTerminalSlotId: data?.truckerTerminalSlotId || "" })
                if (response.status === 200) {
                    showSnackbar('Terminal Record Updated Successfully.', 'success');
                    onClose()
                    fetchDetails("")
                    setSubmitLoader(false)
                }
            } catch (error) {
                console.error("Error:", error);
                showSnackbar(error.response?.data?.message || 'An error occurred while Updating the Terminal Record.', 'error');
                setSubmitLoader(false)
            }
        }
    }


    return (
        <>
            <PopupLayout open={open} onClose={onClose} title={title} handleSubmit={handleSubmit}>

                {/* <--------------------- Trucker Name ---------------------> */}
                <TruckerDropDown size={4} title="Trucker" dropDownValue={recordData.truckerName} dropDownId={recordData.trucker_Id} dropDownState={recordData} setDropDownState={setRecordData} disable={true} />


                {/* <--------------------- Terminal Name ---------------------> */}
                <TerminalDropDown size={4} title="Terminal Name" dropDownValue={recordData.terminalName} dropDownId={recordData.terminal_Id} dropDownState={recordData} setDropDownState={setRecordData} required={true} />


                {/* <--------------------- Day ---------------------> */}
                <DaysDropDown size={4} dropDownValue={recordData.day} dropDownState={recordData} setDropDownState={setRecordData} required={true} />


                {/* <--------------------- Capacity ---------------------> */}
                <InputLayout size={4} title="Capacity" type="number" name="capacity_Per_Day" value={recordData?.capacity_Per_Day} onChange={handleChange} placeholder="Enter Capacity" min='1' max='1000' required={true} />


                {/* <--------------------- Wait Window ---------------------> */}
                <InputLayout size={4} title="Wait Window" type="number" name="scheduler_Wait_Window" value={displayValue} onChange={handleChange} placeholder="Enter Hours" min='0' max='72' />


                {/* <--------------------- Operation Slots ---------------------> */}
                <InputLayout size={4} title="Operation Slots" type="text" name="operating_Slot" value={recordData?.operating_Slot} onChange={handleChange} placeholder="9-13" pattern='^(1?\d|2[0-4])-(1?\d|2[0-4])$' message={"Please Enter Hours in 24hrs format and in 9-13"} required={true} />


                {/* <--------------------- Cut-off Time ---------------------> */}
                <CutOfftimrDropDown size={4} operationHr={recordData?.operating_Slot} dropDownValue={recordData?.cutoff_Time} dropDownState={recordData} setDropDownState={setRecordData} required={true} />


                <div className='d-flex justify-content-end gap-4'>
                    <button className='cancel-btn' onClick={onClose}>Cancel</button>
                    <LoaderBtn loader={submitLoader} title={btn} />
                </div>
            </PopupLayout>
        </>
    )
}

export default SuperTruckerModal