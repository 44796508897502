import React from 'react';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

const LogoutButton = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();

  const handleLogout = () => {
    const currentAccount = instance.getAccountByHomeId(
      "256e5519-dded-4d5e-8e5a-27fcc6b365e9"
    );
    instance
      .logoutRedirect({
        onRedirectNavigate: () => {
          // window.alert("kjnk");
          // Return false to stop navigation after local logout
          // navigate("/logout");
          return false;
        },
      })
      .then(() => {
        navigate("/logout");
        // window.alert("test");
        // Clear local storage and cookies
        localStorage.clear();
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i];
          const eqPos = cookie.indexOf("=");
          const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
        }
        console.log("Logging Out");
        // After clearing storage and cookies, navigate to the front-channel logout URL
        // window.location.href = "https://draytrace-ui.azurewebsites.net/";
      })
      .catch((error) => {
        console.error("Logout error:", error);
      });
  };

  return <button onClick={handleLogout}>Logout</button>;
};

export default LogoutButton;
