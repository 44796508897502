import React, { useState } from 'react'
import { TableRow, TableCell, Button } from "@mui/material"
import TerminalRecordModal from '../../../popup/TruckerPopup/TerminalRecordModal'
import EditIcon from "@mui/icons-material/Edit";
import { convertInTimeRange, convertTimeRange } from '../../../../utils/helperDateTime';

const TruckerProftableRow = ({ data, handleSort }) => {

    const [isEditActive, setIsEditActive] = useState(false)

    const { terminal_Id, terminalName, capacity_Per_Day, scheduler_Wait_Window, day, operating_Slot, cutoff_Time } = data

    return (
        <>
            <TableRow className="tableRow" key={terminalName}>
                <TableCell className="containerId">
                    {terminalName || "--"}
                </TableCell>
                <TableCell >{capacity_Per_Day || "--"}</TableCell>
                <TableCell >{scheduler_Wait_Window || "--"}</TableCell>
                <TableCell >{day || "--"}</TableCell>
                <TableCell >{operating_Slot ? convertTimeRange(operating_Slot) : "--"}</TableCell>
                <TableCell >{cutoff_Time ? convertInTimeRange(cutoff_Time) : "--"}</TableCell>
                <TableCell>
                    <div className='dot-box'>
                        <EditIcon onClick={() => setIsEditActive(true)} className='me-3' />
                    </div>
                </TableCell>
            </TableRow>

            {
                isEditActive && <TerminalRecordModal open={isEditActive} onClose={() => setIsEditActive(false)} title={"Edit Terminal Record"} btn={"Update"} data={data} fetchDetails={handleSort} />
            }

        </>
    )
}

export default TruckerProftableRow