import React, { useContext, useState, useRef, useEffect } from 'react'
import { FormControl, TableContainer } from "@mui/material"

import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Paper } from "@mui/material";
import "./viewappoint.css"

import SearchLimitPopup from "../popup/popup";
import { generateQueryParams, handleList } from '../../utils/helperPages';
import UserAccessCheck from '../UserAccess/UserAccessCheck';
import AppReadyContext from '../../context/AppReadyContext';

import ThemeBtn from '../ui/ThemeBtn';
import Loader from '../ui/Loader';
import PaginationBar from '../ui/PaginationBar';
import PageHeader from '../ui/PageHeader';
import SelectFilter from '../ui/DropDown/SelectFilter';

import AppointmentTable from './AppointmentTable/AppointmentTable';
import { AppointSlot, AppointmentsData, TerminalLists } from '../../axios/api';
import ReportContext from '../../context/ReportContext';


const AppointMentList = () => {

    const { appReady } = useContext(AppReadyContext);
    const { setReportType } = useContext(ReportContext);
    setReportType("appointmentsIcon");

    const [appointmentsData, setAppointmentsData] = useState([])

    const searchInputRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState("");

    const [slot, setSlot] = useState([]);
    const [terminals, setTerminals] = useState([]);

    const [selectedSlot, setSelectedSlot] = useState([])
    const [selectedTerminals, setSelectedTerminal] = useState([])
    const [showSearchLimitPopup, setShowSearchLimitPopup] = useState(false);

    const [debouncedSlots, setDebouncedSlots] = useState([])
    const [debouncedTerminals, setDebouncedTerminals] = useState([])

    const [sortBy, setSortBy] = useState("appointmentDate"); // Column to sort by
    const [sortOrder, setSortOrder] = useState("desc"); // Sorting order (asc, desc)

    const [page, setPage] = useState(0);
    const [pageNo, setPageNo] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRecords, setTotalRecords] = useState(10);


    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleSearch();
        }
    };

    // Search by Conatiner IDs
    const handleSearch = () => {
        setLoading(true)

        // Add searchText as containerIds query parameter
        if (searchText.trim() !== "") {
            const containerIds = searchText
                .trim()
                .split(/[,\s]+/)
                .map((id) => id.trim()) // Trim each container ID to remove empty spaces
                .filter((id) => id !== ""); // Filter out empty container IDs

            const sampleObject = {
                slot: selectedSlot,
                terminal: selectedTerminals,
                PageNumber: pageNo,
                PageSize: rowsPerPage,
                sortingColumn: sortBy,
                sortingOrder: sortOrder,
                containerNumber: containerIds
            }

            if (containerIds.length > 0) {
                if (containerIds.length > 20) {
                    setShowSearchLimitPopup(true);
                    setLoading(false);
                    return;
                }
                appointCall(generateQueryParams(sampleObject))
            }
        }

    }


    // Handle the Asc and Desc for particular column
    const handleSort = async (column) => {
        setLoading(true);
        if (sortBy === column && sortOrder === "asc") {
            setSortOrder("desc");
        } else {
            setSortBy(column);
            setSortOrder("asc");
        }

        let newSortOrder = sortOrder === "asc" ? "desc" : "asc";

        setSortBy(column);
        setSortOrder(newSortOrder);

        const paramsObject = {
            Timeslot: selectedSlot,
            Terminal: selectedTerminals,
            PageNumber: pageNo,
            PageSize: rowsPerPage,
            sortingColumn: column,
            sortingOrder: newSortOrder,
        }

        const params = generateQueryParams(paramsObject)

        appointCall(params)
    }


    const handleFilters = () => {
        setLoading(true);
        setSearchText("");
        setPageNo(0);

        // Call the fetchAppointments function with the current pagination parameters
        fetchAppointments(1, rowsPerPage);
    }


    useEffect(() => {
        const statusTimeout = setTimeout(() => {
            setDebouncedSlots(selectedSlot);
        }, 500);

        return () => {
            clearTimeout(statusTimeout);
        };
    }, [selectedSlot]);


    useEffect(() => {
        const terminalTimeout = setTimeout(() => {
            setDebouncedTerminals(selectedTerminals);
        }, 500);

        return () => {
            clearTimeout(terminalTimeout);
        };
    }, [selectedTerminals]);


    // Debounce to Refresh the Filter DropDown data
    useEffect(() => {
        handleFilters();
    }, [debouncedSlots, debouncedTerminals]);


    const fetchAppointments = async (pageNumber, pageSize) => {
        setLoading(true)

        // console.log(filterHandler(selectedTerminals, terminalList))

        const paramsObject = {
            Timeslot: selectedSlot,
            Terminal: selectedTerminals,
            PageNumber: pageNumber,
            PageSize: pageSize,
            sortingColumn: sortBy,
            sortingOrder: sortOrder,
        }
        const params = generateQueryParams(paramsObject)

        appointCall(params)
    }


    const fetchSlotAndTerminal = async () => {
        // Get the Appointment Slot and termianl for filter list
        try {
            const responseTerminal = await TerminalLists()
            const responseSlot = await AppointSlot()

            setTerminals(responseTerminal.data);
            setSlot(responseSlot.data);
        } catch (error) {
            console.error("Error fetching filters:", error);
        }
    }


    const appointCall = async (params) => {
        try {
            const response = await AppointmentsData(params)

            setAppointmentsData(response.data.appointmentslist)
            setTotalRecords(response.data.pageDetails.totalRecords);
            setLoading(false)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const clearFilters = () => {
        setLoading(true)
        setSelectedSlot([])
        setSelectedTerminal([]);
        setSearchText(""); // Clear the search input field
        setRowsPerPage(10);
        setSortBy("containerNumber");
        setSortOrder("asc");
        setPageNo(0);
    };

    useEffect(() => {
        fetchSlotAndTerminal()
        fetchAppointments(1, rowsPerPage)
    }, [appReady])


    return (
        <>
            <UserAccessCheck pageName={"Appointments"} />
            {
                appReady && <>
                    <div className="dashboard-containerxx db-ctr container-fluid page-content-main">
                        <SearchLimitPopup open={showSearchLimitPopup} onClose={() => setShowSearchLimitPopup(false)} />

                        <PageHeader name={"View Appointment"} />

                        <div className="summary-filters">

                            {/* Terminal DropDown */}
                            <SelectFilter title={"Terminal"} type={"terminal"} options={handleList(terminals)} selectedValue={selectedTerminals} setSelectedValue={setSelectedTerminal} />

                            {/* Slot DropDown */}
                            <SelectFilter title={"Slot"} type={"status"} options={slot} selectedValue={selectedSlot} setSelectedValue={setSelectedSlot} />



                            {/* Search Bar */}
                            <div>
                                <div className="filter-label search-label">
                                    Enter Container IDs
                                </div>
                                <FormControl sx={{ width: 400 }} size="small">
                                    <OutlinedInput
                                        inputRef={searchInputRef}
                                        id="search-input"
                                        type="text"
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        onKeyDown={handleKeyDown}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <Tooltip
                                                    title={
                                                        <Typography variant="body2" className="tooltip-title">
                                                            Enter a maximum of 20 containers separated with
                                                            comma or space
                                                        </Typography>
                                                    }
                                                    placement="top"
                                                >
                                                    <InfoIcon fontSize="smaller" />
                                                </Tooltip>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </div>

                            <ThemeBtn name="Search" handleClick={handleSearch} disabled={searchText.trim() === ""} />
                            <ThemeBtn name="Reset" handleClick={clearFilters} />
                        </div>

                        <div className="summary-section-3">
                            <TableContainer component={Paper}>
                                {
                                    loading ? <Loader /> : <>
                                        <AppointmentTable data={appointmentsData} sortBy={sortBy} sortOrder={sortOrder} page={page} rowsPerPage={rowsPerPage} handleSort={handleSort} />
                                    </>
                                }

                                {
                                    totalRecords !== 0 && <PaginationBar page={page} setPage={setPage} pageNo={pageNo} totalRecords={totalRecords} fetchData={fetchAppointments} setPageNo={setPageNo} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
                                }
                            </TableContainer>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default AppointMentList