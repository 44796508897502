import React from 'react'
import Select from 'react-select';
import { labelConvert } from '../../../utils/helperPages';
import { Col } from 'react-bootstrap';
import RequiredAstrict from '../RequiredAstrict';

const DaysDropDown = ({ dropDownValue, size, title = "Day", dropDownState, setDropDownState, required = false, disable = false }) => {

    // Select Dropdown for Days which is going to use in whole project

    const options = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]


    const handleChange = (e) => {
        if (e === null) {
            setDropDownState({ ...dropDownState, day: "" })
        } else {
            setDropDownState({ ...dropDownState, day: e.value })
        }
    }


    return (
        <>
            <Col lg={size}>
                <div className="input-container">
                    <label htmlFor="">{title} {required && <RequiredAstrict />}</label>
                    <Select
                        className='select-custom'
                        isClearable={true}
                        value={!dropDownValue ? null : { label: dropDownValue, value: dropDownValue }}
                        options={labelConvert(options)}
                        onChange={handleChange}
                        required={required}
                        isDisabled={disable}
                    />
                </div>
            </Col>
        </>
    )
}

export default DaysDropDown