import { createContext, useState } from "react";

const ReportContext = createContext();

export const ReportProvider = ({ children }) => {

  const [reportType, setReportType] = useState("");

  return (
    <ReportContext.Provider value={{ reportType, setReportType }}>
      {children}
    </ReportContext.Provider>
  );
};

export default ReportContext;
