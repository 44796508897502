import MenuItems from './MenuItems';
const Dropdown = ({ submenus, dropdown, depthLevel, head }) => {
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? 'dropdown-submenu' : '';

  return (
    <ul
      className={`custom-dropdown ${dropdownClass} ${dropdown ? 'show' : ''
        }`}
    >
      {submenus.map((submenu, index) => (
        <MenuItems
          items={submenu}
          key={index}
          depthLevel={depthLevel}
          head={head}
        />
      ))}
    </ul>
  );
};

export default Dropdown;
