import React from 'react'
import logo from "../../assets/logo.png"

const PageLoader = () => {
    return (
        <div className="loginWindow">
            <div>
                <img src={logo} height={80} alt="Logo" />
            </div>
            <h3>Redirecting to Login</h3>
            {/* <LoginButton /> */}
        </div>
    )
}

export default PageLoader