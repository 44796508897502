import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const UserAccessCheck = ({ pageName, subPage }) => {

    const [userId, setUserId] = useState(localStorage.getItem("user"));
    const [navigationData, setNavigationData] = useState(
        JSON.parse(localStorage.getItem("Navigation"))
    );
    const navigate = useNavigate();

    const fetchUserIdWithRetry = async (maxRetries, delay) => {
        let retries = 0;

        const getUserId = () => {
            const userIdFromStorage = localStorage.getItem("user");
            const userNavFromStorage = localStorage.getItem("Navigation");
            if (userIdFromStorage && userNavFromStorage) {
                setUserId(userIdFromStorage);
                setNavigationData(JSON.parse(userNavFromStorage));
            } else {
                retries++;
                if (retries <= maxRetries) {
                    setTimeout(getUserId, delay);
                } else {
                    console.error(
                        "Failed to retrieve userId from local storage after",
                        retries,
                        "retries."
                    );
                }
            }
        };

        getUserId();
    };

    useEffect(() => {
        fetchUserIdWithRetry(10, 1000); // Retry up to 10 times with a 1-second delay
    }, []);

    function searchReportId(data, targetReportId) {
        for (const item of data) {
            if (item.name === targetReportId) {
                return true; // Found the reportId
            }
            if (item.subMenu && item.subMenu.length > 0) {
                if (searchReportId(item.subMenu, targetReportId)) {
                    return true; // Found the reportId in a submenu
                }
            }
        }
        return false; // ReportId not found in this branch
    }

    useEffect(() => {


        if (navigationData) {
            const parentMenu = navigationData.find(
                (menu) => menu.name === `${pageName}`
            );

            if (parentMenu) {
                if (parentMenu.subMenu) {
                    const reportIdExists = searchReportId(parentMenu.subMenu, subPage);
                    if (!reportIdExists) {
                        navigate("/unauthorized");
                    }
                }
            } else {
                // Redirect to unauthorized page
                navigate("/unauthorized");
            }
        }
    }, [navigationData, navigate, pageName, subPage]);

    return <div></div>;
};

export default UserAccessCheck;
