import React, { useEffect, useContext, useState } from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { msalConfig } from "./azure-auth-config";

import logo from "../src/assets/logo.png";
import "./App.css";
import AppReadyContext from "./context/AppReadyContext";
import StatusContext from "./context/StatusContext";
import StepWiseContextProvider from "./components/Contexts/StepWiseContexts";

import LoginButton from "./login/LoginButton";
import TokenValidityChecker from "./login/TokenValidityChecker";
import Header from "./menu/Header/Header";
import AppointMentList from "./components/AppointDashboard/AppointMentList";
import Footer from "./components/footer/footer";
import AdminRoute from "./components/Admin/AdminRoute";
import SuperAdminRoute from "./components/SuperAdmin/SuperAdminRoute";
import Unauthorized from "./login/Unauthorized";
import { SnackbarProvider } from "./context/SnackbarContext";
import PostLogoutScreen from "./logout/PostLogoutScreen";
import SideMenu from "./menu/SideBar/SideMenu";
import ReportContext from "./context/ReportContext";

const msalInstance = new PublicClientApplication(msalConfig);

function App() {

  const { reportType } = useContext(ReportContext);
  // const { appReady } = useContext(AppReadyContext);

  const [status, setStatus] = useState("");

  const resetStatus = () => setStatus("")

  const dynamicClassName = reportType ? `report-style-${reportType}` : "";
  const dynamicStyle = `
    .menus > .${reportType} {
      background-color: #7194A8;
    }
  `;

  return (
    <>
      <MsalProvider instance={msalInstance}>
        <Router>
          <div className="App">
            <style>{dynamicStyle}</style>
            <AuthenticatedTemplate>
              <SnackbarProvider>
                <TokenValidityChecker />
                <Header />
                <SideMenu />
                <StatusContext.Provider value={{ status, setStatus, resetStatus }}>
                  <StepWiseContextProvider>
                    <Routes>
                      <Route path="/" element={<AppointMentList />} />
                      <Route path="/admin/*" element={<AdminRoute />} />
                      <Route path="/operation/*" element={<SuperAdminRoute />} />
                      <Route path="/unauthorized" element={<Unauthorized />} />
                      <Route path="/logout" element={<LogoutRedirect />} />
                      <Route path="*" element={<AppointMentList />} />
                    </Routes>
                  </StepWiseContextProvider>
                </StatusContext.Provider>
                <Footer />
              </SnackbarProvider>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
              <Routes>
                <Route path="/logout" element={<PostLogoutScreen />} />
                <Route path="*" element={<LoginButton />} />
              </Routes>
            </UnauthenticatedTemplate>
          </div>

        </Router>
      </MsalProvider>
    </>
  );
}


const LogoutRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Perform any necessary cleanup or post-logout actions here

    // Redirect the user to the desired page after logout
    console.log("Redirecting");
    navigate("/"); // Replace with the desired post-logout redirect URL
  }, [navigate]);

  return null;
};



export default App;
