import React from 'react'
import { Table, TableHead, TableRow, TableCell, TableSortLabel, TableBody } from "@mui/material"
import TerminaltableRow from './TerminaltableRow'
import { tableHead, tableRow } from '../../../../utils/helperInline'

const TerminalInfoTable = ({ data, sortBy, sortOrder, page, rowsPerPage, handleSort }) => {


    const head = ["TERMINAL NAME", "PORT CITY", "STATE", "DAYS", "OPERATION SLOT"]

    const tableHeading = [
        { title: "TERMINAL NAME", sort: "name" },
        { title: "PORT CITY", sort: "city" },
        { title: "STATE", sort: "state" },
        { title: "DAYS", sort: "day" },
        { title: "OPERATION SLOT", sort: "operating_Slot" },
    ]


    return (
        <>
            <Table>
                <TableHead sx={tableHead}>
                    <TableRow>
                        {
                            tableHeading.map((item) => (
                                <TableCell>
                                    <TableSortLabel
                                        active={sortBy === item.sort}
                                        direction={sortOrder}
                                        onClick={() => handleSort(item.sort)}
                                    >
                                        {item.title}
                                    </TableSortLabel>
                                </TableCell>
                            ))
                        }
                        <TableCell>ACTION</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className="tableBody" sx={tableRow}>
                    {
                        data.length !== 0 ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data) => (
                            <TerminaltableRow data={data} handleSort={handleSort} />
                        )) : <>No Record Found</>
                    }
                </TableBody>
            </Table>
        </>
    )
}

export default TerminalInfoTable