import React, { useContext, useEffect, useState } from 'react'

import TerminalHolidayTable from "./TerminalHolidayTable"
import PageHeader from '../../../ui/PageHeader'
import SelectFilter from '../../../ui/DropDown/SelectFilter'
import ThemeBtn from '../../../ui/ThemeBtn'
import { Paper, TableContainer } from '@mui/material'
import Loader from '../../../ui/Loader'
import PaginationBar from '../../../ui/PaginationBar'
import AppReadyContext from '../../../../context/AppReadyContext'
import { useDropDown } from '../../../../context/DropDownContext'
import { generateQueryParams, handleList } from '../../../../utils/helperPages'
import { DeliveryLocationLists, TerminalHoliday, TerminalLists } from '../../../../axios/api'
import TerminalHolidayModal from '../../../popup/TerminalInfoPopup/TerminalHolidayModal'


const TerminalHolidayList = () => {

    const { appReady } = useContext(AppReadyContext);
    const { terminalList, stateList, setTerminalList, setStateList, setClientList } = useDropDown()

    const [holidayData, setHolidayData] = useState([])
    const [isAddActive, setIsddActive] = useState(false)
    const [loading, setLoading] = useState(false);

    const [selectedTerminals, setSelectedTerminal] = useState([])
    const [debouncedTerminals, setDebouncedTerminals] = useState([])


    const [sortBy, setSortBy] = useState("containerID"); // Column to sort by
    const [sortOrder, setSortOrder] = useState("asc"); // Sorting order (asc, desc)

    const [page, setPage] = useState(0);
    const [pageNo, setPageNo] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRecords, setTotalRecords] = useState(10);


    const handleSort = async (column) => {
        setLoading(true);
        if (sortBy === column && sortOrder === "asc") {
            setSortOrder("desc");
        } else {
            setSortBy(column);
            setSortOrder("asc");
        }

        let newSortOrder = sortOrder === "asc" ? "desc" : "asc";

        setSortBy(column);
        setSortOrder(newSortOrder);

        const sampleObject = {
            Terminal: selectedTerminals,
            PageNumber: pageNo,
            PageSize: rowsPerPage,
            sortingColumn: column,
            sortingOrder: newSortOrder,
        }

        const params = generateQueryParams(sampleObject)
        holidayCall(params)
    }

    const handleFilters = () => {
        setLoading(true);
        setPageNo(0);
        // Call the fetchContainerSummary function with the current pagination parameters
        fetchHolidayList(1, rowsPerPage);
    }

    useEffect(() => {
        const statusTimeout = setTimeout(() => {
            setDebouncedTerminals(selectedTerminals);
        }, 500);

        return () => {
            clearTimeout(statusTimeout);
        };
    }, [selectedTerminals]);

    useEffect(() => {
        handleFilters();
    }, [debouncedTerminals]);


    const fetchHolidayList = async (pageNumber, pageSize) => {
        setLoading(true)

        const sampleObject = {
            Terminal: selectedTerminals,
            PageNumber: pageNumber,
            PageSize: pageSize,
            sortingColumn: sortBy,
            sortingOrder: sortOrder,
        }

        const params = generateQueryParams(sampleObject)

        holidayCall(params)
    }


    const holidayCall = async (params) => {
        try {
            const response = await TerminalHoliday(params)
            setHolidayData(response.data.terminalslist)
            setTotalRecords(response.data.pageDetails.totalRecords)
            setLoading(false)
            // setDeliveryData(JSONDATA.deliveryLocation)
            // setTotalRecords(JSONDATA.pageDetails.totalRecords);
            setClientList(["HUDD", "DDSI CSN"])
            setLoading(false)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    // Get the Terminal
    const fetchTerminal = async () => {
        try {
            const response = await TerminalLists()
            // const sanitizedTerminals = response.terminals.filter(
            //     (terminal) => terminal !== ""
            // );
            // setTerminalList(sanitizedTerminals.sort());
            // setTerminalList(response.data);
            // setTerminalList(["CHA-NRA-ECT", "LAX TRM-B302", "LAX-TRM-J234"])
            setTerminalList(response.data)
        } catch (error) {
            console.error("Error fetching filters:", error);
        }
    }

    useEffect(() => {
        fetchHolidayList(1, 10)
        fetchTerminal()
    }, [appReady])

    return (
        <>
            {
                appReady && <>
                    <div className="dashboard-containerxx db-ctr container-fluid page-content-main">
                        <PageHeader name={"Operations"} subName={"Terminal Information"} subPath={"/operation/terminal-info"} subName2={"Terminal Holiday List"} />

                        <div className="summary-filters">
                            <div className="summary-filters">

                                {/* location DropDown */}
                                <SelectFilter title={"Terminal"} type={"location"} options={handleList(terminalList)} selectedValue={selectedTerminals} setSelectedValue={setSelectedTerminal} />
                            </div>

                            <ThemeBtn name="Add Holiday" handleClick={() => setIsddActive(true)} />

                        </div>

                        <div className="summary-section-3">
                            <TableContainer component={Paper}>
                                {
                                    loading ? <Loader /> : <>
                                        <TerminalHolidayTable data={holidayData} sortBy={sortBy} sortOrder={sortOrder} page={page} rowsPerPage={rowsPerPage} handleSort={handleSort} />
                                    </>
                                }

                                {
                                    totalRecords !== 0 && <PaginationBar page={page} setPage={setPage} pageNo={pageNo} totalRecords={totalRecords} fetchData={fetchHolidayList} setPageNo={setPageNo} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
                                }

                            </TableContainer>
                        </div>
                    </div>

                    {
                        isAddActive && <TerminalHolidayModal open={isAddActive} onClose={() => setIsddActive(false)} title={"Add Holiday"} btn={"Add"} fetchDetails={handleSort} />
                    }
                </>
            }
        </>
    )
}

export default TerminalHolidayList