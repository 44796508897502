import React, { useState } from 'react'
import { TableRow, TableCell, Button } from "@mui/material"
import { formatDate } from '../../../../utils/helperDateTime'
import DeliveryHolidayModal from '../../../popup/DeliveryLocationPopup/DeliveryHolidayModal'
import EditIcon from "@mui/icons-material/Edit";

const DeliverytHolidayRow = ({ data, handleSort }) => {

    const [isEditActive, setIsEditActive] = useState(false)

    const { name, deliveryLocation, port, holiday, description } = data

    return (
        <>
            <TableRow className="tableRow" key={deliveryLocation}>
                <TableCell className="containerId ">
                    {deliveryLocation || "--"}
                </TableCell>
                <TableCell >{description || "--"}</TableCell>
                <TableCell >{formatDate(holiday) || "--"}</TableCell>
                <TableCell>
                    <div className='dot-box'>
                        <EditIcon onClick={() => setIsEditActive(true)} className='me-3' />
                    </div>
                </TableCell>
            </TableRow>

            {
                isEditActive && <DeliveryHolidayModal open={isEditActive} onClose={() => setIsEditActive(false)} title={"Update Holiday"} btn={"Update"} data={data} fetchDetails={handleSort} />
            }
        </>
    )
}

export default DeliverytHolidayRow