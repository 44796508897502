import React from 'react'
import Select from 'react-select';
import { labelConvert, labelIdConvert } from '../../../utils/helperPages'
import { useDropDown } from '../../../context/DropDownContext';
import { Col } from 'react-bootstrap';
import RequiredAstrict from '../RequiredAstrict';


const LocationDropDown = ({ dropDownValue, size, title, dropDownId, dropDownState, setDropDownState, required = false, disable = false }) => {

    const { locationList } = useDropDown()

    const handleChange = (e) => {
        if (e === null) {
            setDropDownState({ ...dropDownState, deliveryLocation_Id: "", deliveryLocationName: "" })
        } else {
            setDropDownState({ ...dropDownState, deliveryLocation_Id: e.value, deliveryLocationName: e.label })
        }
    }

    return (
        <>
            <Col lg={size}>
                <div className="input-container">
                    <label htmlFor="">{title} {required && <RequiredAstrict />}</label>
                    <Select
                        className='select-custom'
                        isClearable={true}
                        value={!dropDownValue ? null : { label: dropDownValue, value: dropDownId }}
                        options={labelIdConvert(locationList)}
                        onChange={handleChange}
                        required={required}
                        disable={disable}
                    />
                </div>
            </Col>
        </>
    )
}

export default LocationDropDown