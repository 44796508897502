import React, { useContext, useState, useEffect } from 'react'
import { TableContainer, Paper, } from "@mui/material";

import AppReadyContext from '../../../context/AppReadyContext';
import DeliveryLocTable from './DeliveryTable/DeliveryLocTable';

import PageHeader from '../../ui/PageHeader';
import PaginationBar from '../../ui/PaginationBar';
import Loader from '../../ui/Loader';

import { generateQueryParams, handleList } from '../../../utils/helperPages';
import UserAccessCheck from '../../UserAccess/UserAccessCheck';

import ThemeBtn from '../../ui/ThemeBtn';
import LocationModal from '../../popup/DeliveryLocationPopup/LocationModal';
import { useDropDown } from '../../../context/DropDownContext';
import SelectFilter from '../../ui/DropDown/SelectFilter';
import { DeliveryLocList, DeliveryLocationData, DeliveryLocationLists } from '../../../axios/api';

const DeliveryLocationList = () => {

    const { appReady } = useContext(AppReadyContext);
    const { locationList, setLocationList } = useDropDown()

    const [deliveryData, setDeliveryData] = useState([])

    const [loading, setLoading] = useState(false);
    const [isAddActive, setIsddActive] = useState(false)
    const [selectedLocations, setSelectedLocation] = useState([]);

    const [debouncedLocation, setDebouncedLocation] = useState([]);

    const [sortBy, setSortBy] = useState("containerID"); // Column to sort by
    const [sortOrder, setSortOrder] = useState("asc"); // Sorting order (asc, desc)

    const [page, setPage] = useState(0);
    const [pageNo, setPageNo] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRecords, setTotalRecords] = useState(10);


    const handleSort = async (column) => {
        setLoading(true);
        if (sortBy === column && sortOrder === "asc") {
            setSortOrder("desc");
        } else {
            setSortBy(column);
            setSortOrder("asc");
        }

        let newSortOrder = sortOrder === "asc" ? "desc" : "asc";

        setSortBy(column);
        setSortOrder(newSortOrder);

        const paramsObject = {
            DeliveryLocation: selectedLocations,
            PageNumber: pageNo,
            PageSize: rowsPerPage,
            sortingColumn: column,
            sortingOrder: newSortOrder,
        }

        const params = generateQueryParams(paramsObject)
        deliveryCall(params)
    }

    const handleFilters = () => {
        setLoading(true);
        setPageNo(0);
        // Call the fetchDeliveryLocation function with the current pagination parameters
        fetchDeliveryLocation(1, rowsPerPage);
    }

    useEffect(() => {
        const terminalTimeout = setTimeout(() => {
            setDebouncedLocation(selectedLocations);
        }, 500);

        return () => {
            clearTimeout(terminalTimeout);
        };
    }, [selectedLocations]);

    useEffect(() => {
        handleFilters();
    }, [debouncedLocation]);


    const fetchDeliveryLocation = async (pageNumber, pageSize) => {
        setLoading(true)

        const paramsObject = {
            DeliveryLocation: selectedLocations,
            PageNumber: pageNumber,
            PageSize: pageSize,
            sortingColumn: sortBy,
            sortingOrder: sortOrder,
        }

        const params = generateQueryParams(paramsObject)

        deliveryCall(params)
    }


    // Fetch Delivery Location
    const fetchLocations = async () => {
        try {
            const responseLocation = await DeliveryLocationLists()

            // const sanitizedTerminals = response.terminals.filter(
            //     (terminal) => terminal !== ""
            // );

            // setLocationList(sanitizedTerminals.sort());
            setLocationList(responseLocation?.data)
        } catch (error) {
            console.error("Error fetching filters:", error);
        }
    }


    const deliveryCall = async (params) => {
        try {
            const response = await DeliveryLocationData(params)
            setDeliveryData(response.data.deliveryLocationslist)
            setTotalRecords(response.data.pageDetails.totalRecords);
            setLoading(false)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }


    useEffect(() => {
        fetchDeliveryLocation(1, rowsPerPage)
        fetchLocations()
    }, [appReady])



    return (
        <>

            <UserAccessCheck pageName={"Admin Portal"} subPage={"Delivery Location Profile"} />
            {
                appReady && <>
                    <div className="dashboard-containerxx db-ctr container-fluid page-content-main">
                        <PageHeader name={"Admin Portal"} subName={"Delivery Location Profile"} />

                        <div className="summary-filters">


                            {/* Location DropDown */}
                            <SelectFilter title={"Delivery Location"} type={"location"} options={handleList(locationList)} selectedValue={selectedLocations} setSelectedValue={setSelectedLocation} />


                            <ThemeBtn name="Add Details" handleClick={() => setIsddActive(true)} />

                        </div>

                        <div className="summary-section-3">
                            <TableContainer component={Paper}>
                                {
                                    loading ? <Loader /> : <>
                                        <DeliveryLocTable data={deliveryData} sortBy={sortBy} sortOrder={sortOrder} page={page} rowsPerPage={rowsPerPage} handleSort={handleSort} />
                                    </>
                                }

                                {
                                    totalRecords !== 0 && <PaginationBar page={page} setPage={setPage} pageNo={pageNo} totalRecords={totalRecords} fetchData={fetchDeliveryLocation} setPageNo={setPageNo} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
                                }

                            </TableContainer>
                        </div>

                    </div>

                    {
                        isAddActive && <LocationModal open={isAddActive} onClose={() => setIsddActive(false)} title={"Add Record"} btn={"Add"} fetchDetails={handleSort} />
                    }
                </>
            }
        </>

    )
}

export default DeliveryLocationList