import React, { useState } from 'react'
import { useSnackbar } from '../../../context/SnackbarContext';
import PopupLayout from '../../ui/PopupLayout';
import InputLayout from '../../ui/InputLayout';
import LoaderBtn from '../../ui/LoaderBtn';
import { AddNewLocationName, DeliveryLocationLists } from '../../../axios/api';
import { useDropDown } from '../../../context/DropDownContext';

const LocationNameModal = ({ open, onClose, fetchDetails }) => {

    const showSnackbar = useSnackbar();
    const [submitLoader, setSubmitLoader] = useState(false)
    const { setLocationList } = useDropDown()


    const [locationName, setLocationName] = useState({
        name: "",
        address: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setLocationName({ ...locationName, [name]: value })
    }

    const handleAddTerminal = async (e) => {
        e.preventDefault()
        setSubmitLoader(true)
        try {
            const response = await AddNewLocationName(locationName)
            if (response.status === 200) {
                showSnackbar('New Delivery Location Added Successfully', 'success')
                const responseLocation = await DeliveryLocationLists()
                setLocationList(responseLocation?.data)
                onClose()
                setSubmitLoader(false)
            }
        } catch (error) {
            console.error("Error:", error);
            showSnackbar(error.response?.data?.message || 'An error occurred while Adding the New Delivery Location.', 'error');
            setSubmitLoader(false)
        }
    }


    return (
        <PopupLayout open={open} onClose={onClose} title={"Add New Delivery Location "} handleSubmit={handleAddTerminal}>

            {/* <--------------------- Location Name ---------------------> */}
            <InputLayout size={12} title="Delivery Location" type="text" name="name" value={locationName?.name} onChange={handleChange} placeholder="Enter Delivery Location Name" required={true} />


            {/* <--------------------- Address ---------------------> */}
            <InputLayout size={12} title="Address" type="text" name="address" value={locationName?.address} onChange={handleChange} placeholder="Enter Address" />


            <div className='d-flex justify-content-end gap-4'>
                <button className='cancel-btn' onClick={onClose}>Cancel</button>
                <LoaderBtn loader={submitLoader} title={"Add"} />
            </div>
        </PopupLayout>
    )
}

export default LocationNameModal