import React, { useState } from 'react'
import { StyledDialog, StyledDialogContent, StyledDialogTitle } from '../popupstyled'
import { IconButton } from '@mui/material'
import CloseIcon from "@mui/icons-material/Close";
import { Col, Container, Row } from 'react-bootstrap'
import LocationDropDown from '../../ui/DropDown/LocationDropDown';
import DaysDropDown from '../../ui/DropDown/DaysDropDown';
import RequiredAstrict from '../../ui/RequiredAstrict';
import { useSnackbar } from '../../../context/SnackbarContext';
import { AddDeliveryProfile, UpdateDeliveryProfile } from '../../../axios/api';
import { handleStringWithNull } from '../../../utils/helperPages';
import PopupLayout from '../../ui/PopupLayout';
import InputLayout from '../../ui/InputLayout';
import LoaderBtn from '../../ui/LoaderBtn';
import CutOfftimrDropDown from '../../ui/DropDown/CutOfftimrDropDown';

const LocationModal = ({ open, onClose, title, btn, data, fetchDetails }) => {

    const showSnackbar = useSnackbar();
    const [submitLoader, setSubmitLoader] = useState(false)

    const [locationData, setLocationData] = useState({
        deliveryLocation_Id: data?.deliveryLocation_Id || "",
        deliveryLocationName: data?.deliveryLocationName || "",
        day: data?.day || "",
        operating_Slot: data?.operating_Slot || "",
        capacity_Per_Day: data?.capacity_Per_Day || "",
        cutoff_Time: data?.cutoff_Time || "",
        // address: data?.address || "",
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === "capacity_Per_Day") {
            setLocationData({ ...locationData, [name]: parseInt(value) })
        } else {
            setLocationData({ ...locationData, [name]: value })
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setSubmitLoader(true)
        if (btn === "Add") {
            // To Add the Delivery Location
            try {
                const response = await AddDeliveryProfile(handleStringWithNull(locationData))
                if (response.status === 200) {
                    showSnackbar('Delivery Loction Profile Added Successfully.', 'success');
                    onClose()
                    fetchDetails("")
                    setSubmitLoader(false)
                }
            } catch (error) {
                console.error("Error:", error);
                setSubmitLoader(false)
                showSnackbar(error.response?.data?.message || 'An error occurred while Adding the Delivery Location.', 'error');
            }
        } else if (btn === "Update") {

            // To Update the Terminal Record
            try {
                const response = await UpdateDeliveryProfile(handleStringWithNull({ ...locationData, deliveryLocationTruckerId: data?.deliveryLocationTruckerId, deliveryLocationSlotId: data?.deliveryLocationSlotId, deliveryLocation_Id: data?.deliveryLocation_Id }))
                if (response.status === 200) {
                    showSnackbar('Delivery Loction Profile Updated Successfully.', 'success');
                    onClose()
                    fetchDetails("")
                    setSubmitLoader(false)
                }
            } catch (error) {
                console.error("Error:", error);
                setSubmitLoader(false)
                showSnackbar(error.response?.data?.message || 'An error occurred while Updating the Delivery Location.', 'error');
            }
        }
    }

    return (
        <>
            <PopupLayout open={open} onClose={onClose} title={title} handleSubmit={handleSubmit}>

                {/* <--------------------- Location Name ---------------------> */}
                <LocationDropDown size={8} title={"Delivery Location"} dropDownValue={locationData.deliveryLocationName} dropDownId={locationData.deliveryLocation_Id} dropDownState={locationData} setDropDownState={setLocationData} required={true} />


                {/* <--------------------- Day ---------------------> */}
                <DaysDropDown size={4} dropDownValue={locationData.day} dropDownState={locationData} setDropDownState={setLocationData} />

                {/* <--------------------- Capacity ---------------------> */}
                <InputLayout size={4} title="Capacity" type="number" name="capacity_Per_Day" value={locationData?.capacity_Per_Day} onChange={handleChange} placeholder="Enter Capacity" min='1' max='1000' />

                {/* <--------------------- Hours of Operation ---------------------> */}
                <InputLayout size={4} title="Hours of Operation" type="text" name="operating_Slot" value={locationData?.operating_Slot} onChange={handleChange} placeholder="9-13" pattern='^(1?\d|2[0-4])-(1?\d|2[0-4])$' message={"Please Enter Hours in 24hrs format and in 9-13"} />

                {/* <--------------------- Cut-off Time ---------------------> */}
                <CutOfftimrDropDown size={4} operationHr={locationData?.operating_Slot} dropDownValue={locationData?.cutoff_Time} dropDownState={locationData} setDropDownState={setLocationData} />


                {/* <--------------------- Address ---------------------> */}
                {/* <InputLayout size={12} title="Address" type="text" name="address" value={locationData?.address} onChange={handleChange} placeholder="Enter Address" /> */}

                <div className='d-flex justify-content-end gap-4'>
                    <button className='cancel-btn' onClick={onClose}>Cancel</button>
                    <LoaderBtn loader={submitLoader} title={btn} />
                </div>
            </PopupLayout>
        </>
    )
}

export default LocationModal