import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import "../popup.css"
import PortDropDown from '../../ui/DropDown/PortDropDown';
import StateDropDown from '../../ui/DropDown/StateDropDown';
import DaysDropDown from '../../ui/DropDown/DaysDropDown';
import RequiredAstrict from '../../ui/RequiredAstrict';
import { AddTerminalData, AddTerminalName, UpdateTerminalData } from '../../../axios/api';
import { useSnackbar } from '../../../context/SnackbarContext';
import PopupLayout from '../../ui/PopupLayout';
import LoaderBtn from '../../ui/LoaderBtn';
import InputLayout from '../../ui/InputLayout';
import ThemeBtn from '../../ui/ThemeBtn';
import TerminalDropDown from '../../ui/DropDown/TerminalDropDown';
import TerminalNameModal from './TerminalNameModal';

const TerminalInfoPopUpModal = ({ open, onClose, title, btn, data, fetchDetails }) => {

    const showSnackbar = useSnackbar();
    const [submitLoader, setSubmitLoader] = useState(false)
    const [isTerminalName, setIsTerminalName] = useState(false)


    const [terminalData, setTerminalData] = useState({
        terminalName: data?.name || "",
        terminal_Id: data?.terminal_Id || "",
        day: data?.day || "",
        operating_Slot: data?.operating_Slot || "",
    })



    const handleChange = (e) => {
        const { name, value } = e.target
        setTerminalData({ ...terminalData, [name]: value })
    }


    const handleSubmit = async (e) => {
        e.preventDefault()
        setSubmitLoader(true)
        if (btn === "Add") {
            // To Add Terminal Info
            try {
                const response = await AddTerminalData(terminalData)
                if (response.status === 200) {
                    showSnackbar('Terminal Information Added Successfully.', 'success');
                    onClose()
                    fetchDetails("")
                    setSubmitLoader(false)
                }
            } catch (error) {
                console.error("Error:", error);
                setSubmitLoader(false)
                showSnackbar(error.response?.data?.message || 'An error occurred while Adding the Terminal Information.', 'error');
            }
        } else if (btn === "Update") {

            // To Update Terminal Info
            try {
                const response = await UpdateTerminalData({ ...terminalData, id: data?.id, terminal_Id: data?.terminal_Id })
                if (response.status === 200) {
                    showSnackbar('Terminal Information Updated Successfully.', 'success');
                    onClose()
                    fetchDetails("")
                    setSubmitLoader(false)
                }
            } catch (error) {
                console.error("Error:", error);
                showSnackbar(error.response?.data?.message || 'An error occurred while Adding the Terminal Information.', 'error');
            }
        }
    }




    return (
        <>
            {
                !isTerminalName ? <PopupLayout open={open} onClose={onClose} title={title} handleSubmit={handleSubmit}>

                    <TerminalDropDown size={btn !== "Update" ? 4 : 4} title="Terminal Name" dropDownValue={terminalData.terminalName} dropDownId={terminalData.terminal_Id} dropDownState={terminalData} setDropDownState={setTerminalData} required={true} />

                    {
                        btn !== "Update" && <Col lg={2}>
                            <div className='d-flex align-items-center add-extra-btn'>
                                <ThemeBtn width="100%" name={"Add New"} handleClick={() => setIsTerminalName(true)} />
                            </div>
                        </Col>
                    }

                    {/* <--------------------- Day ---------------------> */}
                    <DaysDropDown size={btn !== "Update" ? 3 : 4} dropDownValue={terminalData.day} dropDownState={terminalData} setDropDownState={setTerminalData} />


                    {/* <--------------------- Operation Slots ---------------------> */}
                    <InputLayout size={btn !== "Update" ? 3 : 4} title="Operation Slots" type="text" name="operating_Slot" value={terminalData?.operating_Slot} onChange={handleChange} placeholder="9-13" pattern='^(1?\d|2[0-4])-(1?\d|2[0-4])$' message={"Please Enter Hours in 24hrs format and in 9-13"} />


                    <div className='d-flex justify-content-end gap-4'>
                        <button className='cancel-btn' onClick={onClose}>Cancel</button>
                        <LoaderBtn loader={submitLoader} title={btn} />
                    </div>
                </PopupLayout> : <>
                    <TerminalNameModal open={open} onClose={() => setIsTerminalName(false)} title={"Add New Terminal"} fetchDetails={fetchDetails} />
                </>
            }
        </>
    )
}

export default TerminalInfoPopUpModal