import React, { useState } from 'react';

export const StepWiseContexts = React.createContext({});

function StepWiseContextProvider(props) {
  const [client, setClient] = useState('');
  const [terminal, setTerminal] = useState('');
  const [rows, setRows] = useState([]);

  return (
    <StepWiseContexts.Provider
      value={{ client, setClient, terminal, setTerminal, rows, setRows }}
    >
      {props.children}
    </StepWiseContexts.Provider>
  );
}

export default StepWiseContextProvider;
