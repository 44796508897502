import React, { useState } from 'react'
import { TableRow, TableCell, Button } from "@mui/material"
import LocationModal from '../../../popup/DeliveryLocationPopup/LocationModal'
import EditIcon from "@mui/icons-material/Edit";
import { convertInTimeRange, convertTimeRange } from '../../../../utils/helperDateTime';

const DeliverytableRow = ({ data, handleSort }) => {

    const [isEditActive, setIsEditActive] = useState(false)

    const { id, deliveryLocationName, address, day, operating_Slot, capacity_Per_Day, cutoff_Time } = data


    return (
        <>
            <TableRow className="tableRow" key={deliveryLocationName}>
                <TableCell className="containerId ">
                    {deliveryLocationName || "--"}
                </TableCell>
                <TableCell >{address || "--"}</TableCell>
                <TableCell >{day || "--"}</TableCell>
                <TableCell >{operating_Slot ? convertTimeRange(operating_Slot) : "--"}</TableCell>
                <TableCell >{capacity_Per_Day || "--"}</TableCell>
                <TableCell >{cutoff_Time ? convertInTimeRange(cutoff_Time) : "--"}</TableCell>
                <TableCell>
                    <div className='dot-box'>
                        <EditIcon onClick={() => setIsEditActive(true)} className='me-3' />
                    </div>
                </TableCell>
            </TableRow>

            {
                isEditActive && <LocationModal open={isEditActive} onClose={() => setIsEditActive(false)} title={"Edit Record"} btn={"Update"} data={data} fetchDetails={handleSort} />
            }
        </>
    )
}

export default DeliverytableRow