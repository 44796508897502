import React, { useContext, useState, useEffect } from "react";
import { FormControl, Select, Checkbox, MenuItem, ListItemText, TableContainer, Button, Paper, } from "@mui/material";

import AppReadyContext from "../../../context/AppReadyContext";
import UserAccessCheck from "../../UserAccess/UserAccessCheck";
import { useAuth } from "../../../context/AuthContext";

import PageHeader from "../../ui/PageHeader";
import ThemeBtn from "../../ui/ThemeBtn";
import Loader from "../../ui/Loader";
import PaginationBar from "../../ui/PaginationBar";

import { generateQueryParams, handleList } from "../../../utils/helperPages";
import TerminalInfoTable from "./TerminalTable/TerminalInfoTable";
import JSONDATA from "../../../utils/MockData/terminalInfo.json"
import { MenuProps } from "../../../utils/helperInline";
import TerminalInfoPopUpModal from "../../popup/TerminalInfoPopup/TerminalInfoPopUpModal";
import { useDropDown } from "../../../context/DropDownContext";
import { useNavigate } from "react-router-dom";
import SelectFilter from "../../ui/DropDown/SelectFilter";
import { TerminalInfoData, TerminalLists } from "../../../axios/api";

const TerminalInfoList = () => {

    const { appReady } = useContext(AppReadyContext);
    const { terminalList, stateList, setTerminalList, setStateList } = useDropDown()

    const navigate = useNavigate()
    const [isAddActive, setIsddActive] = useState(false)
    const [infoData, setInfoData] = useState([])
    const [loading, setLoading] = useState(false);
    const [selectedState, setSelectedState] = useState([]);
    const [selectedTerminals, setSelectedTerminal] = useState([]);

    const [debouncedState, setDebouncedState] = useState([]);
    const [debouncedTerminals, setDebouncedTerminals] = useState([]);

    const [sortBy, setSortBy] = useState("containerID"); // Column to sort by
    const [sortOrder, setSortOrder] = useState("asc"); // Sorting order (asc, desc)

    const [page, setPage] = useState(0);
    const [pageNo, setPageNo] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRecords, setTotalRecords] = useState(10);


    const handleSort = async (column) => {
        setLoading(true);
        if (sortBy === column && sortOrder === "asc") {
            setSortOrder("desc");
        } else {
            setSortBy(column);
            setSortOrder("asc");
        }

        let newSortOrder = sortOrder === "asc" ? "desc" : "asc";

        setSortBy(column);
        setSortOrder(newSortOrder);

        const paramsObject = {
            Terminal: selectedTerminals,
            State: selectedState,
            PageNumber: pageNo,
            PageSize: rowsPerPage,
            sortingColumn: column,
            sortingOrder: newSortOrder,
        }

        terminalCall(generateQueryParams(paramsObject))
    }

    const handleFilters = () => {
        setLoading(true);
        setPageNo(0);
        // Call the fetchContainerSummary function with the current pagination parameters
        fetchTermianlInfo(1, rowsPerPage);
    }

    useEffect(() => {
        const statusTimeout = setTimeout(() => {
            setDebouncedState(selectedState);
        }, 500);

        return () => {
            clearTimeout(statusTimeout);
        };
    }, [selectedState]);

    useEffect(() => {
        const terminalTimeout = setTimeout(() => {
            setDebouncedTerminals(selectedTerminals);
        }, 500);

        return () => {
            clearTimeout(terminalTimeout);
        };
    }, [selectedTerminals]);

    useEffect(() => {
        handleFilters();
    }, [debouncedState, debouncedTerminals]);


    const fetchTermianlInfo = async (pageNumber, pageSize) => {
        setLoading(true)

        const paramsObject = {
            Terminal: selectedTerminals,
            State: selectedState,
            PageNumber: pageNumber,
            PageSize: pageSize,
            sortingColumn: sortBy,
            sortingOrder: sortOrder,
        }


        terminalCall(generateQueryParams(paramsObject))
    }

    // Get the State and termianl for filter list
    const fetchStateAndTerminal = async () => {
        try {
            const response = await TerminalLists()

            // const response = await InfoStateAndTermianl()
            // const sanitizedTerminals = response.terminals.filter(
            //     (terminal) => terminal !== ""
            // );
            setTerminalList(response.data)
            setStateList(["California"])
        } catch (error) {
            console.error("Error fetching filters:", error);
        }
    }

    const terminalCall = async (params) => {
        try {
            const response = await TerminalInfoData(params)
            setInfoData(response.data.terminalslist)
            setTotalRecords(response.data.pageDetails.totalRecords);
            setLoading(false)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }


    useEffect(() => {
        fetchTermianlInfo(1, rowsPerPage)
        fetchStateAndTerminal()
    }, [appReady])


    return (
        <>

            <UserAccessCheck
                pageName={"Operations"}
                subPage={"Terminal Information"}
            />
            {appReady && (
                <>
                    <div className="dashboard-containerxx db-ctr container-fluid page-content-main">
                        <PageHeader
                            name={"Operations"}
                            subName={"Terminal Information"}
                        />

                        <div className="summary-filters">

                            <div className="summary-filters">
                                {/* Terminal DropDown */}
                                <SelectFilter title={"Terminal"} type={"terminal"} options={handleList(terminalList)} selectedValue={selectedTerminals} setSelectedValue={setSelectedTerminal} />

                                {/* State DropDown */}
                                <SelectFilter title={"State"} type={"state"} options={stateList} selectedValue={selectedState} setSelectedValue={setSelectedState} />
                            </div>

                            <ThemeBtn name="Add Terminal" handleClick={() => setIsddActive(true)} />

                            <ThemeBtn name="Holiday List" handleClick={() => navigate('/operation/terminal-holiday')} />
                        </div>

                        <div className="summary-section-3">
                            <TableContainer component={Paper}>
                                {
                                    loading ? <Loader /> : <>
                                        <TerminalInfoTable data={infoData} sortBy={sortBy} sortOrder={sortOrder} page={page} rowsPerPage={rowsPerPage} handleSort={handleSort} />
                                    </>
                                }

                                {
                                    totalRecords !== 0 && <PaginationBar page={page} setPage={setPage} pageNo={pageNo} totalRecords={totalRecords} fetchData={fetchTermianlInfo} setPageNo={setPageNo} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
                                }


                            </TableContainer>
                        </div>
                    </div>

                    {
                        isAddActive && <TerminalInfoPopUpModal open={isAddActive} onClose={() => setIsddActive(false)} title={"Add Terminal Slots"} btn={"Add"} fetchDetails={handleSort} />
                    }
                </>
            )}
        </>

    );
};

export default TerminalInfoList;
