import React, { useState } from 'react'
import { TableRow, TableCell, Button } from "@mui/material"
import TerminalInfoPopUpModal from '../../../popup/TerminalInfoPopup/TerminalInfoPopUpModal'
import EditIcon from "@mui/icons-material/Edit";
import { convertTimeRange } from '../../../../utils/helperDateTime';

const TerminaltableRow = ({ data, handleSort }) => {

    const { id, name, city, state, day, operating_Slot } = data

    const [isEditActive, setIsEditActive] = useState(false)

    return (
        <>
            <TableRow className="tableRow" key={name}>
                <TableCell className="containerId">
                    {name || "--"}
                </TableCell>
                <TableCell>{city || "--"}</TableCell>
                <TableCell>{state || "--"}</TableCell>
                <TableCell>{day || "--"}</TableCell>
                <TableCell>{operating_Slot ? convertTimeRange(operating_Slot) : "--"}</TableCell>
                <TableCell>
                    <div className='dot-box'>
                        <EditIcon onClick={() => setIsEditActive(true)} className='me-3' />
                    </div>
                </TableCell>
            </TableRow>

            {
                isEditActive && <TerminalInfoPopUpModal open={isEditActive} onClose={() => setIsEditActive(false)} title={"Edit Terminal Slots"} btn={"Update"} data={data} fetchDetails={handleSort} />
            }

        </>
    )
}

export default TerminaltableRow