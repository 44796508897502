import React, { useState } from 'react'
import DaysDropDown from '../../ui/DropDown/DaysDropDown';
import LocationDropDown from '../../ui/DropDown/LocationDropDown';
import TruckerDropDown from '../../ui/DropDown/TruckerDropDown';
import { useSnackbar } from '../../../context/SnackbarContext';
import { AddDeliveryProfile, UpdateDeliveryProfile } from '../../../axios/api';
import { handleStringWithNull } from '../../../utils/helperPages';
import LoaderBtn from '../../ui/LoaderBtn';
import PopupLayout from '../../ui/PopupLayout';
import InputLayout from '../../ui/InputLayout';
import CutOfftimrDropDown from '../../ui/DropDown/CutOfftimrDropDown';
import { Col } from 'react-bootstrap';
import ThemeBtn from '../../ui/ThemeBtn';
import LocationNameModal from './LocationNameModal';

const SuperLocationModal = ({ open, onClose, title, btn, data, fetchDetails }) => {

    const showSnackbar = useSnackbar();
    const [submitLoader, setSubmitLoader] = useState(false)
    const [isDeliveryName, setIsDeliveryName] = useState(false)
    const [isManage, setIsManage] = useState(!data?.clientName ? true : false)

    const [locationData, setLocationData] = useState({
        clientName: data?.clientName || "",
        // trucker_ClientId: data?.trucker_ClientId || 0,
        deliveryLocation_Id: data?.deliveryLocation_Id || "",
        deliveryLocationName: data?.deliveryLocationName || "",
        trucker_Id: data?.trucker_Id || "",
        truckerName: data?.truckerName || "",
        // address: data?.address || "",
        day: data?.day || "",
        operating_Slot: data?.operating_Slot || "",
        capacity_Per_Day: data?.capacity_Per_Day || "",
        cutoff_Time: data?.cutoff_Time || ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === "capacity") {
            setLocationData({ ...locationData, [name]: parseInt(value) })
        } else {
            setLocationData({ ...locationData, [name]: value })
        }
    }

    const handleManage = (e) => {
        setIsManage(e.target.checked)
        setLocationData({ ...locationData, clientName: "" })
    }


    const handleSubmit = async (e) => {
        e.preventDefault()
        setSubmitLoader(true)
        if (btn === "Add") {

            // To Add the Delivery Location
            try {
                const response = await AddDeliveryProfile(handleStringWithNull(locationData))
                if (response.status === 200) {
                    showSnackbar('Delivery Loction Profile Added Successfully.', 'success');
                    onClose()
                    fetchDetails("")
                    setSubmitLoader(false)
                }
            } catch (error) {
                console.error("Error:", error);
                setSubmitLoader(false)
                showSnackbar(error.response?.data?.message || 'An error occurred while Adding the Delivery Location.', 'error');
            }
        } else if (btn === "Update") {
            console.log(data)
            // To Update the Terminal Record
            try {
                const response = await UpdateDeliveryProfile(handleStringWithNull({ ...locationData, deliveryLocationTruckerId: data?.deliveryLocationTruckerId, deliveryLocationSlotId: data?.deliveryLocationSlotId, deliveryLocation_Id: data?.deliveryLocation_Id, trucker_ClientId: data?.trucker_ClientId }))
                if (response.status === 200) {
                    showSnackbar('Delivery Loction Profile Updated Successfully.', 'success');
                    onClose()
                    fetchDetails("")
                    setSubmitLoader(false)
                }
            } catch (error) {
                console.error("Error:", error);
                setSubmitLoader(false)
                showSnackbar(error.response?.data?.message || 'An error occurred while Updating the Delivery Location.', 'error');
            }
        }
    }

    return (
        <>
            {!isDeliveryName ?
                <PopupLayout open={open} onClose={onClose} title={title} handleSubmit={handleSubmit}>

                    <Col lg={12}>
                        <div className="input-container">
                            <div className="input-checkbox">
                                <input type="checkbox" checked={isManage} onChange={handleManage} />
                                <label className='m-0' htmlFor="">Manage By Trucker </label>
                            </div>

                        </div>
                    </Col>


                    {/* <--------------------- Client Name ---------------------> */}
                    <InputLayout size={3} title="Client" type="text" name="clientName" value={locationData?.clientName} onChange={handleChange} placeholder="Enter Client Name" disable={isManage} required={!isManage} />

                    {/* <--------------------- Location Name ---------------------> */}
                    {/* <InputLayout size={3} title="Delivery Location" type="text" name="deliveryLocationName" value={locationData?.deliveryLocationName} onChange={handleChange} placeholder="Enter Delivery Location Name" required={true} /> */}


                    <LocationDropDown size={btn === "Update" ? 5 : 4} title={"Delivery Location"} dropDownValue={locationData.deliveryLocationName} dropDownId={locationData.deliveryLocation_Id} dropDownState={locationData} setDropDownState={setLocationData} required={true} />

                    {
                        btn !== "Update" && <Col lg={2}>
                            <div className='d-flex align-items-center add-extra-btn'>
                                <ThemeBtn width="100%" name={"Add New"} handleClick={() => setIsDeliveryName(true)} />
                            </div>
                        </Col>
                    }

                    {/* <--------------------- Trucker Name ---------------------> */}
                    <TruckerDropDown size={btn === "Update" ? 4 : 3} title="Trucker" dropDownValue={locationData.truckerName} dropDownId={locationData.trucker_Id} dropDownState={locationData} setDropDownState={setLocationData} required={true} />


                    {/* <--------------------- Day ---------------------> */}
                    <DaysDropDown size={3} dropDownValue={locationData.day} dropDownState={locationData} setDropDownState={setLocationData} />


                    {/* <--------------------- Capacity ---------------------> */}
                    <InputLayout size={3} title="Capacity" type="number" name="capacity_Per_Day" value={locationData?.capacity_Per_Day} onChange={handleChange} placeholder="Enter Capacity" min='1' max='1000' />


                    {/* <--------------------- Hours of Operation ---------------------> */}
                    <InputLayout size={3} title="Hours of Operation" type="text" name="operating_Slot" value={locationData?.operating_Slot} onChange={handleChange} placeholder="9-13" pattern='^(1?\d|2[0-4])-(1?\d|2[0-4])$' message={"Please Enter Hours in 24hrs format and in 9-13"} />


                    {/* <--------------------- Cut-off Time ---------------------> */}
                    <CutOfftimrDropDown size={3} operationHr={locationData?.operating_Slot} dropDownValue={locationData?.cutoff_Time} dropDownState={locationData} setDropDownState={setLocationData} />


                    {/* <--------------------- Address ---------------------> */}
                    {/* <InputLayout size={12} title="Address" type="text" name="address" value={locationData?.address} onChange={handleChange} placeholder="Enter Address" /> */}


                    <div className='d-flex justify-content-end gap-4'>
                        <button className='cancel-btn' onClick={onClose}>Cancel</button>
                        <LoaderBtn loader={submitLoader} title={btn} />
                    </div>
                </PopupLayout> : <>
                    <LocationNameModal open={open} onClose={() => setIsDeliveryName(false)} title={"Add New Terminal"} fetchDetails={fetchDetails} />
                </>

            }
        </>
    )
}

export default SuperLocationModal