import React, { createContext, useContext, useState } from "react";

const DropDownContext = createContext();

export const useDropDown = () => {
    return useContext(DropDownContext)
}

export const DropDownProvider = ({ children }) => {

    const [terminalList, setTerminalList] = useState([])
    const [truckerList, setTruckerList] = useState([])
    const [locationList, setLocationList] = useState([])
    const [clientList, setClientList] = useState([])
    const [stateList, setStateList] = useState([])

    const contextValue = {
        terminalList,
        truckerList,
        locationList,
        stateList,
        clientList,
        setTerminalList,
        setTruckerList,
        setLocationList,
        setStateList,
        setClientList
    }

    return (
        <DropDownContext.Provider value={contextValue} >{children}</DropDownContext.Provider>
    )

}
