import React, { useState } from 'react'
import { FormControl, Select, Checkbox, MenuItem, ListItemText, TableContainer } from "@mui/material"
import { MenuProps } from '../../../utils/helperInline'

const SelectFilter = ({ title, type, options, selectedValue, setSelectedValue }) => {

    const [dropDownOpen, setDropDownOpen] = useState(false)

    const handleDropdown = (e) => {
        // console.log(e.target.value)
        setSelectedValue(e.target.value)
        setTimeout(() => {
            setDropDownOpen(false);
        }, 200);
    }

    return (
        <>
            <div>
                <div className="filter-label">{title}</div>
                <FormControl
                    className="status-dropdown"
                    sx={{ width: 200 }}
                    size="small"
                >
                    <Select
                        className="status-select"
                        labelId="status-label"
                        id="status-select"
                        multiple
                        value={selectedValue}
                        onChange={handleDropdown}
                        renderValue={(selected) => selected.join(", ")}
                        open={Boolean(dropDownOpen)}
                        onOpen={() => setDropDownOpen(true)}
                        onClose={() => setDropDownOpen(false)}
                        MenuProps={MenuProps}
                    >
                        {/* <MenuItem value=""><em>Status</em></MenuItem> */}
                        {options.map((el) => (
                            <MenuItem key={el} value={el}>
                                <Checkbox checked={selectedValue.indexOf(el) > -1} />
                                <ListItemText primary={el} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </>
    )
}

export default SelectFilter