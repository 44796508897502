import React from 'react'
import { Col } from 'react-bootstrap'
import RequiredAstrict from '../RequiredAstrict'
import Select from 'react-select';

const CutOfftimrDropDown = ({ dropDownValue, size, operationHr, title, dropDownId, dropDownState, setDropDownState, required = false, disable = false }) => {

    // Select Dropdown for Terminal which is going to use in whole project

    // const timeArray = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24']
    const timeArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]


    const getObjectsWithinOperationHours = (operationHours, dataArray) => {
        const [startHour, endHour] = operationHours.split('-').map(Number);
        let arr = []

        const filteredArray = dataArray
            .filter((item) => {
                const hour = parseInt(item);
                return hour > startHour && hour <= endHour;
            })
            .map((item) => item);

        filteredArray.map((item) => {
            arr.push({ value: item, label: item })
        })

        return arr;
    };



    const handleChange = (e) => {
        if (e === null) {
            setDropDownState({ ...dropDownState, cutoff_Time: "" })
        } else {
            setDropDownState({ ...dropDownState, cutoff_Time: parseInt(e.value) })
        }
    }

    return (
        <Col lg={size}>
            <div className="input-container">
                <label htmlFor="">Cut-off Time {required && <RequiredAstrict />}</label>
                <Select
                    className='select-custom'
                    isClearable={true}
                    value={!dropDownValue ? null : { label: dropDownValue, value: dropDownValue }}
                    options={getObjectsWithinOperationHours(operationHr, timeArray)}
                    onChange={handleChange}
                    required={required}
                    isDisabled={disable}
                />
            </div>
        </Col>
    )
}

export default CutOfftimrDropDown