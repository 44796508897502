import React from 'react'
import Select from 'react-select';
import { useDropDown } from '../../../context/DropDownContext'
import { labelIdConvert } from '../../../utils/helperPages';
import { Col } from 'react-bootstrap';
import RequiredAstrict from '../RequiredAstrict';

const TerminalDropDown = ({ dropDownValue, size, title, dropDownId, dropDownState, setDropDownState, required = false, disable = false }) => {

    // Select Dropdown for Terminal which is going to use in whole project

    const { terminalList } = useDropDown()

    const handleChange = (e) => {
        if (e === null) {
            setDropDownState({ ...dropDownState, terminal_Id: "", terminalName: "" })
        } else {
            setDropDownState({ ...dropDownState, terminal_Id: e.value, terminalName: e.label })
        }
    }

    return (
        <>
            <Col lg={size}>
                <div className="input-container">
                    <label htmlFor="">{title} {required && <RequiredAstrict />}</label>
                    <Select
                        className='select-custom'
                        isClearable={true}
                        value={!dropDownValue ? null : { label: dropDownValue, value: dropDownId }}
                        options={labelIdConvert(terminalList)}
                        onChange={handleChange}
                        required={required}
                        isDisabled={disable}
                    />
                </div>
            </Col>
        </>
    )
}

export default TerminalDropDown