import React, { useContext, useEffect, useState } from 'react'
import { TableContainer, Paper, } from "@mui/material";

import AppReadyContext from '../../../../context/AppReadyContext';
import { useDropDown } from '../../../../context/DropDownContext';

import { generateQueryParams, handleList } from '../../../../utils/helperPages';
import PageHeader from '../../../ui/PageHeader';
import SelectFilter from '../../../ui/DropDown/SelectFilter';
import Loader from '../../../ui/Loader';
import PaginationBar from '../../../ui/PaginationBar';
import DeliveryHolidayTable from './DeliveryHolidayTable';
import { DeliveryLocHoliday, DeliveryLocationLists, TruckerClientList } from '../../../../axios/api';
import DeliveryHolidayModal from '../../../popup/DeliveryLocationPopup/DeliveryHolidayModal';
import ThemeBtn from '../../../ui/ThemeBtn';

const DeliveryHolidayList = () => {

    const { appReady } = useContext(AppReadyContext);
    const { locationList, setLocationList, clientList, setClientList } = useDropDown()

    const [holidayData, setHolidayData] = useState([])
    const [isAddActive, setIsddActive] = useState(false)
    const [loading, setLoading] = useState(false);

    const [selectedClient, setSelectedClient] = useState([])
    const [selectedLocations, setSelectedLocation] = useState([]);

    const [debouncedClient, setDebouncedClient] = useState([])
    const [debouncedLocation, setDebouncedLocation] = useState([])

    const [sortBy, setSortBy] = useState("containerID"); // Column to sort by
    const [sortOrder, setSortOrder] = useState("asc"); // Sorting order (asc, desc)

    const [page, setPage] = useState(0);
    const [pageNo, setPageNo] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRecords, setTotalRecords] = useState(10);


    const handleSort = async (column) => {
        setLoading(true);
        if (sortBy === column && sortOrder === "asc") {
            setSortOrder("desc");
        } else {
            setSortBy(column);
            setSortOrder("asc");
        }

        let newSortOrder = sortOrder === "asc" ? "desc" : "asc";

        setSortBy(column);
        setSortOrder(newSortOrder);

        const sampleObject = {
            DeliveryLocation: selectedLocations,
            Clients: selectedClient,
            PageNumber: pageNo,
            PageSize: rowsPerPage,
            sortingColumn: column,
            sortingOrder: newSortOrder,
        }

        const params = generateQueryParams(sampleObject)
        holidayCall(params)
    }

    const handleFilters = () => {
        setLoading(true);
        setPageNo(0);
        // Call the fetchContainerSummary function with the current pagination parameters
        fetchHolidayList(1, rowsPerPage);
    }

    useEffect(() => {
        const terminalTimeout = setTimeout(() => {
            setDebouncedClient(selectedClient);
        }, 500);

        return () => {
            clearTimeout(terminalTimeout);
        };
    }, [selectedClient]);

    useEffect(() => {
        const terminalTimeout = setTimeout(() => {
            setDebouncedLocation(selectedLocations);
        }, 500);

        return () => {
            clearTimeout(terminalTimeout);
        };
    }, [selectedLocations]);

    useEffect(() => {
        handleFilters();
    }, [debouncedClient, debouncedLocation]);


    const fetchHolidayList = async (pageNumber, pageSize) => {
        setLoading(true)

        const sampleObject = {
            DeliveryLocation: selectedLocations,
            Clients: selectedClient,
            PageNumber: pageNumber,
            PageSize: pageSize,
            sortingColumn: sortBy,
            sortingOrder: sortOrder,
        }

        const params = generateQueryParams(sampleObject)

        holidayCall(params)
    }


    const holidayCall = async (params) => {
        try {
            const response = await DeliveryLocHoliday(params)
            setHolidayData(response.data.deliveryLocationslist)
            setTotalRecords(response.data.pageDetails.totalRecords)
            setLoading(false)
            // setDeliveryData(JSONDATA.deliveryLocation)
            // setTotalRecords(JSONDATA.pageDetails.totalRecords);
            // setClientList(["HUDD", "DDSI CSN"])
            setLoading(false)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const fetchDeliveryLocation = async () => {
        try {
            const responseLocation = await DeliveryLocationLists()
            const responseClient = await TruckerClientList()
            setLocationList(responseLocation?.data)
            setClientList(responseClient?.data)
        } catch (error) {
            console.error("Error fetching filters:", error);
        }
    }


    useEffect(() => {
        fetchHolidayList(1, 10)
        fetchDeliveryLocation()
    }, [appReady])



    return (
        <>
            {
                appReady && <>
                    <div className="dashboard-containerxx db-ctr container-fluid page-content-main">
                        <PageHeader name={"Operations"} subName={"Delivery Location Profile "} subPath={"/operation/delivery-location-profile"} subName2={"Holiday List"} />

                        <div className="summary-filters">

                            <div className="summary-filters">
                                {/* Client DropDown */}
                                <SelectFilter title={"Client"} type={"client"} options={handleList(clientList)} selectedValue={selectedClient} setSelectedValue={setSelectedClient} />


                                {/* location DropDown */}
                                <SelectFilter title={"Delivery Location"} type={"location"} options={handleList(locationList)} selectedValue={selectedLocations} setSelectedValue={setSelectedLocation} />
                            </div>

                            <ThemeBtn name="Add Holiday" handleClick={() => setIsddActive(true)} />


                        </div>

                        <div className="summary-section-3">
                            <TableContainer component={Paper}>
                                {
                                    loading ? <Loader /> : <>
                                        <DeliveryHolidayTable data={holidayData} sortBy={sortBy} sortOrder={sortOrder} page={page} rowsPerPage={rowsPerPage} handleSort={handleSort} />
                                    </>
                                }

                                {
                                    totalRecords !== 0 && <PaginationBar page={page} setPage={setPage} pageNo={pageNo} totalRecords={totalRecords} fetchData={fetchHolidayList} setPageNo={setPageNo} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
                                }

                            </TableContainer>
                        </div>
                    </div>

                    {
                        isAddActive && <DeliveryHolidayModal open={isAddActive} onClose={() => setIsddActive(false)} title={"Add Holiday"} btn={"Add"} fetchDetails={handleSort} />
                    }
                </>
            }
        </>
    )
}

export default DeliveryHolidayList