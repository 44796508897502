import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import RequiredAstrict from '../../ui/RequiredAstrict';
import { UpdateProfile } from '../../../axios/api';
import { useSnackbar } from '../../../context/SnackbarContext';
import PopupLayout from '../../ui/PopupLayout';
import LoaderBtn from '../../ui/LoaderBtn';
import InputLayout from '../../ui/InputLayout';

const ProfileModal = ({ open, onClose, title, data, setData }) => {

    const showSnackbar = useSnackbar();
    const [submitLoader, setSubmitLoader] = useState(false)

    const [profileData, setProfileData] = useState({
        name: data?.name || "",
        email: data?.email || "",
        mobile: data?.mobile || ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setProfileData({ ...profileData, [name]: value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setSubmitLoader(true)
        try {
            const response = await UpdateProfile(profileData)
            if (response.status === 200) {
                showSnackbar("Profile Updated Successfully", "success")
                setSubmitLoader(false)
                onClose()
                setData(profileData)
            }
        } catch (error) {
            console.error("Error:", error);
            showSnackbar(error.response?.data?.message || 'An error occurred while Updating the Profile.', 'error');
        }
    }


    return (
        <>
            <PopupLayout open={open} onClose={onClose} title={title} handleSubmit={handleSubmit}>

                {/* <--------------------- Profile Name ---------------------> */}
                <InputLayout size={12} title="Profile Name" type="text" name="name" value={profileData?.name} onChange={handleChange} placeholder="Enter Name" />


                {/* <--------------------- Email ---------------------> */}
                <InputLayout size={6} title="Email" type="email" name="email" value={profileData?.email} onChange={handleChange} placeholder="example@example.com" pattern="^(?!.*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}).*$" required={true} />


                {/* <--------------------- Mobile ---------------------> */}
                <InputLayout size={6} title="Mobile" type="tel" name="mobile" value={profileData?.mobile} onChange={handleChange} placeholder="+1 888-878-9080" pattern="^\+\d{1,4} \d{3}-\d{3}-\d{4}$" required={true} message={"+1 888-878-9080"} />


                <div className='d-flex justify-content-end gap-4'>
                    <button className='cancel-btn' onClick={onClose}>Cancel</button>
                    <LoaderBtn loader={submitLoader} title={"Submit"} />
                </div>
            </PopupLayout>
        </>
    )
}

export default ProfileModal