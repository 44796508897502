import React, { useEffect, useState } from 'react'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import ProfileModal from '../../popup/TruckerPopup/ProfileModal';
import { TruckerProfileDetials } from '../../../axios/api';
import EditIcon from "@mui/icons-material/Edit";

const TruckerProfileContainer = () => {

    const [activeEdit, setActiveEdit] = useState(false)
    const [profileDetails, setProfileDetails] = useState({
        name: "",
        email: "",
        mobile: ""
    })

    const fetchDetails = async () => {
        try {
            const response = await TruckerProfileDetials("")
            const { name, email, mobile } = response.data
            setProfileDetails({ name: name || "", email: email || "", mobile: mobile || "" })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchDetails()
    }, [])


    return (
        <>
            <div className="position-relative me-4">
                <div className="profile-container">
                    <div className="truck-icon">
                        <LocalShippingOutlinedIcon />
                    </div>
                    <div className='profile-content'>
                        <h4>{profileDetails?.name}</h4>
                        <div className='profile-contact '>
                            <span>{profileDetails?.email}</span>
                            <span>{profileDetails?.mobile}</span>
                        </div>
                    </div>

                </div>
                <div onClick={() => setActiveEdit(true)} className="profile-edit">
                    <EditIcon />
                </div>
            </div>

            {
                activeEdit && <ProfileModal title={"Edit Profile"} data={profileDetails} setData={setProfileDetails} open={activeEdit} onClose={() => setActiveEdit(false)} />
            }
        </>
    )
}

export default TruckerProfileContainer