import React, { useContext, useEffect, useState } from 'react'
import { TableContainer, Paper, } from "@mui/material";
import { useNavigate, useParams } from 'react-router-dom';

import UserAccessCheck from '../../UserAccess/UserAccessCheck';
import SuperTruckerTable from './TruckerTable/SuperTruckerTable';

import PageHeader from '../../ui/PageHeader';
import ThemeBtn from '../../ui/ThemeBtn';
import Loader from '../../ui/Loader';
import PaginationBar from '../../ui/PaginationBar';

import AppReadyContext from '../../../context/AppReadyContext';
import { useAuth } from '../../../context/AuthContext';

import { decodeURL, generateQueryParams, handleList } from '../../../utils/helperPages';
import SuperTruckerModal from '../../popup/TruckerPopup/SuperTruckerModal';
import { useDropDown } from '../../../context/DropDownContext';
import SelectFilter from '../../ui/DropDown/SelectFilter';
import { TerminalLists, TruckerLists, TruckerProfileData } from '../../../axios/api';


const SuperTruckerProfile = () => {

    const { id } = useParams()

    const { appReady } = useContext(AppReadyContext);
    const { terminalList, truckerList, setTerminalList, setTruckerList } = useDropDown()

    const [truckerData, setTruckerData] = useState([])
    const [loading, setLoading] = useState(false);
    const [isAddActive, setIsddActive] = useState(false)

    const [selectedTerminals, setSelectedTerminal] = useState([])

    const [debouncedTerminals, setDebouncedTerminals] = useState([])

    const [sortBy, setSortBy] = useState("terminalName"); // Column to sort by
    const [sortOrder, setSortOrder] = useState("asc"); // Sorting order (asc, desc)

    const [page, setPage] = useState(0);
    const [pageNo, setPageNo] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRecords, setTotalRecords] = useState(10);

    const handleSort = async (column) => {
        setLoading(true);
        if (sortBy === column && sortOrder === "asc") {
            setSortOrder("desc");
        } else {
            setSortBy(column);
            setSortOrder("asc");
        }

        let newSortOrder = sortOrder === "asc" ? "desc" : "asc";

        setSortBy(column);
        setSortOrder(newSortOrder);

        const paramsObject = {
            Terminal: selectedTerminals,
            Trucker: decodeURL(id),
            PageNumber: pageNo,
            PageSize: rowsPerPage,
            sortingColumn: column,
            sortingOrder: newSortOrder,
        }

        truckerCall(generateQueryParams(paramsObject))
    }

    const handleFilters = () => {
        setLoading(true);
        setPageNo(0);
        // Call the fetchContainerSummary function with the current pagination parameters
        fetchTruckerProfile(1, rowsPerPage);
    }

    useEffect(() => {
        const statusTimeout = setTimeout(() => {
            setDebouncedTerminals(selectedTerminals);
        }, 500);

        return () => {
            clearTimeout(statusTimeout);
        };
    }, [selectedTerminals]);


    useEffect(() => {
        handleFilters()
    }, [debouncedTerminals])

    const fetchTruckerProfile = async (pageNumber, pageSize) => {
        setLoading(true)

        const paramsObject = {
            Terminal: selectedTerminals,
            Trucker: decodeURL(id),
            PageNumber: pageNumber,
            PageSize: pageSize,
            sortingColumn: sortBy,
            sortingOrder: sortOrder,
        }

        truckerCall(generateQueryParams(paramsObject))
    }

    const truckerCall = async (params) => {
        try {
            const response = await TruckerProfileData(params)
            setTruckerData(response.data.truckerTerminalslist)
            setTotalRecords(response.data.pageDetails.totalRecords);
            setLoading(false)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const fetchTruckerTerminal = async () => {
        // Get trucker and Terminal for the filter list 
        try {
            const responseTerminal = await TerminalLists()
            const responseTrucker = await TruckerLists()
            setTerminalList(responseTerminal.data)
            setTruckerList(responseTrucker.data)
        } catch (error) {
            console.error("Error fetching filters:", error);
        }
    }

    useEffect(() => {
        fetchTruckerProfile(1, rowsPerPage)
        fetchTruckerTerminal()
    }, [appReady])

    const clearFilters = () => {
        setLoading(true)
        setSelectedTerminal([]);
        setRowsPerPage(10);
        setSortBy("terminalName");
        setSortOrder("asc");
        setPageNo(0);
    }

    return (
        <>
            {
                appReady && <>
                    <div className="dashboard-containerxx db-ctr container-fluid page-content-main">
                        <PageHeader name={"Operations"} subName={"Trucker Profile"} subPath={"/operation/trucker-profile/"} subName2={decodeURL(id)} />


                        <div className="summary-filters">
                            <div className='summary-filters'>

                                {/* Terminal DropDown */}
                                <SelectFilter title={"Terminal"} type={"terminal"} options={handleList(terminalList)} selectedValue={selectedTerminals} setSelectedValue={setSelectedTerminal} />

                                <ThemeBtn name="Reset" handleClick={clearFilters} />
                            </div>
                            <ThemeBtn name="Add/Assign Terminal" handleClick={() => setIsddActive(true)} />
                        </div>

                        <div className="summary-section-3">
                            <TableContainer component={Paper}>
                                {
                                    loading ? <Loader /> : <>
                                        <SuperTruckerTable data={truckerData} sortBy={sortBy} sortOrder={sortOrder} page={page} rowsPerPage={rowsPerPage} handleSort={handleSort} />
                                    </>
                                }

                                {
                                    totalRecords !== 0 && <PaginationBar page={page} setPage={setPage} pageNo={pageNo} totalRecords={totalRecords} fetchData={fetchTruckerProfile} setPageNo={setPageNo} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
                                }

                            </TableContainer>
                        </div>
                    </div>

                    {
                        isAddActive && <SuperTruckerModal open={isAddActive} onClose={() => setIsddActive(false)} title={"Add/Assign Terminal Record"} btn={"Add"} fetchDetails={handleSort} />
                    }
                </>
            }
        </>
    )
}

export default SuperTruckerProfile