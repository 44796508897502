import React, { useState, useEffect } from "react";
import "./SideBar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HomeIcon from "@mui/icons-material/Home";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Link } from "react-router-dom";
import dailyIcon from "../../assets/daily.png";
import operationsIcon from "../../assets/operations.png";
import financeIcon from "../../assets/finance.png";
import onDemandIcon from "../../assets/ondemand.png";
import dailyIconNew from "../../assets/daily-icon.png";
import requestIcon from "../../assets/requests-icon.png";
import visualsIcon from "../../assets/visuals-icon.png";
import containerIcon from "../../assets//container_1.svg";
import containerManagementIcon from "../../assets/import.png";
import exceptionsIcon from "../../assets/exceptions.png";
import dashboardIcon from "../../assets/dashboard.png";
import adminPortalIcon from "../../assets/admin-portal.png";

const MenuItem = ({ item }) => {
  // console.log(item)
  const [hovered, setHovered] = useState(false);
  const [parentRef, setParentRef] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  const toggleSubMenu = () => {
    setIsOpen(!isOpen);
  };
  const getIconForMenuItem = (iconName) => {
    switch (iconName) {
      case "Operations":
        return operationsIcon;
      case "Dashboard":
        return dashboardIcon;
      case "Container Summary":
        return containerIcon;
      case "Container Management":
        return containerManagementIcon;
      case "Exceptions":
        return exceptionsIcon;
      case "Admin Portal":
        return adminPortalIcon;
      case "Appointments":
        return dailyIcon;
      default:
        return null;
    }
  };

  return (
    <div
      className="sidebar-submenu-item"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={setParentRef}
    >
      {/* {item.isFirstLayer && (
        <span className="menu-icon">
          <img
            height={24}
            src={getIconForMenuItem(item.title)}
            alt={item.icon}
          />
          <HomeIcon />
        </span>
      )} */}
      <Link className="menu-name-url w-100" to={item.url}>
        <span className="menu-name h-100" onClick={toggleSubMenu}>
          {item.url ? (
            <>
              {item.isFirstLayer && (
                <span className="menu-icon">
                  <img
                    height={24}
                    src={getIconForMenuItem(item.title)}
                    alt={item.icon}
                  />
                  {/* <HomeIcon /> */}
                </span>
              )}
              {item.title}
            </>
          ) : (
            <span className="menu-name-text">{item.title}</span>
          )}
          {item.submenu && (
            <span className="submenu-arrows">
              {" "}
              <ArrowRightIcon />{" "}
            </span>
          )}
        </span>
      </Link>

      {item.submenu && (hovered || item.isParentHovered) && (
        <div
          className="submenu-details"
          style={{
            left: parentRef
              ? parentRef.getBoundingClientRect().width + "px"
              : 0,
          }}
        >
          {item.submenu.map((subItem, subIndex) => (
            <MenuItem
              key={subIndex}
              item={subItem}
              parentRef={parentRef}
              isParentHovered={hovered}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const Menus = ({ menuItems, menuItemsBottom }) => {
  const [sidebarMenuItems, setSidebarMenuItems] = useState([]);
  const [sidebarMenuItemsBottom, setSidebarMenuItemsBottom] = useState([]);

  const handleClick = (index) => {
    if (sidebarMenuItems[index].submenu !== undefined) {
      // console.log(sidebarMenuItems[index].submenu.length);
    }
  };

  useEffect(() => {
    // console.log(menuItems);
    setSidebarMenuItems(menuItems || []);
    setSidebarMenuItemsBottom(menuItemsBottom || []);
  }, [menuItems, menuItemsBottom]);

  const getIconForMenuItem = (iconName) => {
    switch (iconName) {
      case "Operations":
        return operationsIcon;
      case "Dashboard":
        return dashboardIcon;
      case "Container Summary":
        return containerIcon;
      case "Container Management":
        return containerManagementIcon;
      case "Exceptions":
        return exceptionsIcon;
      case "Admin Portal":
        return adminPortalIcon;
      case "Appointments":
        return dailyIcon;
      default:
        return null;
    }
  };

  return (
    <nav className="side-nav-menu">
      <div className="sidebar-mainmenu menu-top">
        <div className="sidebar-mainmenu-item">
          {sidebarMenuItems.map((item, index) => (
            <span key={index}>
              <img
                height={24}
                src={getIconForMenuItem(item.title)}
                alt={item.icon}
              />
            </span>
          ))}
        </div>
        <div className="mainmenu-details">
          {sidebarMenuItems.map((item, index) => (
            <MenuItem key={index} item={item} />
          ))}
        </div>
      </div>

      {/* <div className="sidebar-mainmenu menu-bottom">
        <div className="sidebar-mainmenu-item">
          {sidebarMenuItemsBottom.map((item, index) => (
            <span key={index}></span>
          ))}
        </div>
        <div className="mainmenu-details menu-bottom-details">
          {sidebarMenuItemsBottom.map((item, index) => (
            <div
              className="sidebar-submenu-item"
              key={index}
              onClick={() => handleClick(index)}
            >
              <span className="menu-icon"></span>
              <span>{item.title}</span>
              {item.submenu !== undefined && (
                <div className="submenu-details">
                  {item.submenu.map((subItem, subIndex) => (
                    <div className="sidebar-child-item" key={subIndex} href="#">
                      {subItem}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div> */}
    </nav>
  );
};

export default Menus;
