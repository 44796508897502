import React from 'react'
import { Table, TableHead, TableRow, TableCell, TableSortLabel, TableBody } from "@mui/material"
import { tableHead, tableRow } from '../../../../utils/helperInline'
import DeliverytHolidayRow from './DeliverytHolidayRow'


const DeliveryHolidayTable = ({ data, sortBy, sortOrder, page, rowsPerPage, handleSort }) => {


    const tableHeading = [
        { title: "DELIVERY LOCATION", sort: "deliveryLocation" },
        { title: "HOLIDAY", sort: "description" },
        { title: "DATE", sort: "holiday" },
    ]

    return (
        <>
            <Table>
                <TableHead sx={tableHead}>
                    <TableRow>
                        {
                            tableHeading.map((item) => (
                                <TableCell>
                                    <TableSortLabel
                                        active={sortBy === item.sort}
                                        direction={sortOrder}
                                        onClick={() => handleSort(item.sort)}
                                    >
                                        {item.title}
                                    </TableSortLabel>
                                </TableCell>
                            ))
                        }
                        <TableCell>ACTION</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className="tableBody" sx={tableRow}>
                    {
                        data.length !== 0 ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data) => (
                            <DeliverytHolidayRow data={data} handleSort={handleSort} />
                        )) : <>No Record Found</>
                    }
                </TableBody>
            </Table>
        </>
    )
}

export default DeliveryHolidayTable