import React, { useState, useRef, useEffect, useContext } from "react";
import Menus from "./Menus";
import { faHome, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import AppReadyContext from "../../context/AppReadyContext";
import containerIcon from "../../assets/container.png";
import containerManagementIcon from "../../assets/import.png";
import exceptionsIcon from "../../assets/exceptions.png";
import dashboardIcon from "../../assets/dashboard.png";
import adminPortalIcon from "../../assets/admin-portal.png";
import MenuItems from "../Header/MenuItems";

const SideMenu = () => {
  const { appReady } = useContext(AppReadyContext);
  const navigationData = localStorage.getItem("Navigation");
  const [transformedMenus, setTransformedMenus] = useState([]);

  useEffect(() => {
    if (appReady) {
      try {
        const parsedNavigationData = JSON.parse(navigationData);
        setTransformedMenus(transformMenuData(JSON.parse(navigationData)));
        // console.log(parsedNavigationData);
        // console.log(transformedMenus);
      } catch (error) {
        console.error("Error parsing navigation data:", error);
      }
    }
  }, [appReady, navigationData]);

  const transformMenuData = (data, isFirstLayer = true) => {
    return data.map((menuItem) => {
      const transformedItem = {
        title: menuItem.name,
        url: menuItem.path,
        reportId: menuItem.reportId || "",
        reportType: menuItem.reportType || "",
        datasetId: menuItem.datasetId,
        icon: menuItem.icon,
        path: menuItem.path.split("/")[1],
        isFirstLayer: isFirstLayer, // Set the isFirstLayer property
      };
  
      if (menuItem.subMenu && menuItem.subMenu.length > 0) {
        transformedItem.submenu = transformMenuData(menuItem.subMenu, false); // Pass false for submenu items
      }
  
      return transformedItem;
    });
  };

  const generateUrl = (menuItem) => {
    const { reportType, reportId, datasetId, path } = menuItem;
    const normalizedReportType = reportType.toLowerCase();
    const pathV = path.split("/")[1];

    // Check if the path contains 'Input Parameter'
    if (path.includes("Input Parameter")) {
      const pathParts = path.split("/"); // Split the path by '/'
      const reportName = pathParts[2];
      // Replace 'Input Parameter' with the desired value in the URL
      return `/report/${pathV}/${reportName}/${normalizedReportType}/inputParameters/${reportId}/${datasetId}`;
    } else {
      const pathParts = path.split("/"); // Split the path by '/'
      const reportName = pathParts[2];
      // Use the original path if 'Input Parameter' is not present
      return `/report/${pathV}/${reportName}/${normalizedReportType}/latestReport/${reportId}/${datasetId}`;
    }
  };

  const getIconForMenuItem = (iconName) => {
    switch (iconName) {
      case "dashboardIcon":
        return dashboardIcon;
      case "containerIcon":
        return containerIcon;
      case "containerManagementIcon":
        return containerManagementIcon;
      case "exceptionsIcon":
        return exceptionsIcon;
      case "adminPortalIcon":
        return adminPortalIcon;
      default:
        return null;
    }
  };

  const menuItems = [
    {
      title: "DashBoard",
      icon: faHome,
      submenu: ["DashBoard1", "DashBoard2", "DashBoard3", "DashBoard4"],
    },
    {
      title: "Content",
      icon: faHome,
      submenu: ["Content1", "Content2", "Content3"],
    },
    {
      title: "Invite",
      icon: faUserPlus,
    },
    {
      title: "Analytics",
      icon: faHome,
      submenu: ["Analytics1", "Analytics2", "Analytics3"],
    },
    {
      title: "Guide",
      icon: faHome,
      submenu: ["Guide1", "Guide2", "Guide3"],
    },
  ];

  return (
    <>
        <Menus menuItems={transformedMenus} />
    </>
  );
};

export default SideMenu;
