import React, { useState } from 'react'
import { FormControl, Select, Checkbox, MenuItem, ListItemText, TableContainer, Button } from "@mui/material"

const PaginationBar = ({ pageNo, setPageNo, totalRecords, rowsPerPage, setRowsPerPage, fetchData }) => {


    const handlePaginationChangeNew = (event, newPage) => {
        setPageNo(newPage);
        fetchData(newPage + 1, rowsPerPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(event.target.value);
        fetchData(pageNo + 1, event.target.value);
    };


    // Calculate the last page based on the total records and rows per page
    const lastPage = Math.ceil(totalRecords / rowsPerPage);
    const generatePageNumbers = () => {
        const pageNumbers = [];
        const maxPageNumbers = 3; // Maximum number of page numbers to display

        if (lastPage <= maxPageNumbers) {
            // If there are fewer pages than the maximum, display all pages
            pageNumbers.push(
                ...Array.from({ length: lastPage }, (_, index) => ({
                    pageNumber: index + 1,
                    clickable: true,
                }))
            );
        } else {
            // Otherwise, display a subset of page numbers based on the current page
            const middlePage = Math.ceil(maxPageNumbers / 2);
            let startPage = Math.max(pageNo - middlePage + 2, 1);
            const endPage = Math.min(startPage + maxPageNumbers - 1, lastPage);

            if (endPage === lastPage) {
                startPage = Math.max(endPage - maxPageNumbers + 1, 1);
            }

            if (startPage > 1) {
                // Display the first page if it is not already included
                pageNumbers.push({
                    pageNumber: 1,
                    clickable: true,
                });
                if (startPage > 2) {
                    // Display ellipsis if there is a gap between first page and startPage
                    pageNumbers.push({
                        pageNumber: "...",
                        clickable: false,
                    });
                }
            }

            // Display the page numbers between startPage and endPage
            pageNumbers.push(
                ...Array.from({ length: endPage - startPage + 1 }, (_, index) => ({
                    pageNumber: startPage + index,
                    clickable: true,
                }))
            );

            if (endPage < lastPage) {
                if (endPage < lastPage - 1) {
                    // Display ellipsis if there is a gap between endPage and last page
                    pageNumbers.push({
                        pageNumber: "...",
                        clickable: false,
                    });
                }
                // Display the last page if it is not already included
                pageNumbers.push({
                    pageNumber: lastPage,
                    clickable: true,
                });
            }
        }

        return pageNumbers;
    };

    const handleFirstPage = () => {
        // setLoading(true);
        // setPage(0);
        setPageNo(0);
        handlePaginationChangeNew(null, 0);
    };

    const handleLastPage = () => {
        // setLoading(true);
        // setPage(lastPage - 1);
        setPageNo(lastPage - 1);
        handlePaginationChangeNew(null, lastPage - 1);
    };


    // const isCurrentPage = (pageNumber) => pageNumber === page + 1;


    return (
        <>
            <div className="custom-pagination">
                <Button
                    className="pagination-button"
                    onClick={handleFirstPage}
                    disabled={pageNo === 0}
                >
                    {"<<"}
                </Button>
                <Button
                    className="pagination-button"
                    onClick={(event) => {
                        event.stopPropagation();
                        handlePaginationChangeNew(event, pageNo - 1);
                    }}
                    disabled={pageNo === 0}
                >
                    {"<"}
                </Button>
                {generatePageNumbers().map((pageInfo, index) => (
                    <Button
                        key={index}
                        className={`pagination-button ${pageInfo.pageNumber === pageNo + 1 ? "current-page" : ""
                            }`}
                        onClick={(event) =>
                            handlePaginationChangeNew(event, pageInfo.pageNumber - 1)
                        }
                        disabled={!pageInfo.clickable}
                    >
                        {pageInfo.pageNumber}
                    </Button>
                ))}
                <Button
                    className="pagination-button"
                    onClick={(event) => {
                        event.stopPropagation();
                        handlePaginationChangeNew(event, pageNo + 1);
                    }}
                    disabled={pageNo === lastPage - 1}
                >
                    {">"}
                </Button>
                <Button
                    className="pagination-button"
                    onClick={handleLastPage}
                    disabled={pageNo === lastPage - 1}
                >
                    {">>"}
                </Button>

                <FormControl className="rows-per-page" size="small">
                    <Select
                        labelId="rows-per-page-label"
                        id="rows-per-page-select"
                        inputProps={{ "aria-label": "Without label" }}
                        value={rowsPerPage}
                        onChange={handleRowsPerPageChange}
                    >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                    </Select>
                </FormControl>
            </div>
        </>
    )
}

export default PaginationBar