import React from 'react'
import { Col } from 'react-bootstrap'
import RequiredAstrict from './RequiredAstrict'

const InputLayout = ({ size, title, type, name, value, onChange, placeholder, min = false, max = false, mixlength = false, maxLength = false, required = false, pattern = false, message = false, disable = false }) => {
    return (
        <>
            <Col lg={size}>
                <div className="input-container">
                    <label htmlFor="">{title} {required && <RequiredAstrict />}</label>
                    <div className={`input-field ${disable && "disable-input-field"}`} >
                        <input
                            type={type}
                            value={value}
                            onChange={onChange}
                            name={name}
                            placeholder={placeholder}
                            required={required}
                            pattern={pattern}
                            min={min}
                            max={max}
                            title={message}
                            disabled={disable}
                        />
                    </div>
                </div>
            </Col>
        </>
    )
}

export default InputLayout