import React, { useState } from 'react'
import { AddTerminalName, TerminalLists } from '../../../axios/api'
import PopupLayout from '../../ui/PopupLayout';
import InputLayout from '../../ui/InputLayout';
import { Col } from 'react-bootstrap';
import RequiredAstrict from '../../ui/RequiredAstrict';
import PortDropDown from '../../ui/DropDown/PortDropDown';
import StateDropDown from '../../ui/DropDown/StateDropDown';
import LoaderBtn from '../../ui/LoaderBtn';
import { useSnackbar } from '../../../context/SnackbarContext';
import { useDropDown } from '../../../context/DropDownContext';

const TerminalNameModal = ({ open, onClose, fetchDetails }) => {


    const showSnackbar = useSnackbar();
    const [submitLoader, setSubmitLoader] = useState(false)
    const { terminalList, setTerminalList } = useDropDown()

    const [terminalName, setTerminalName] = useState({
        name: "",
        city: "",
        state: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setTerminalName({ ...terminalName, [name]: value })
    }

    const handleAddTerminal = async (e) => {
        e.preventDefault()
        setSubmitLoader(true)
        try {
            const response = await AddTerminalName(terminalName)
            if (response.status === 200) {
                showSnackbar('New Terminal Added Successfully.', 'success');
                const resTerminal = await TerminalLists()
                setTerminalList(resTerminal.data)
                onClose()
                setSubmitLoader(false)
            }
        } catch (error) {
            console.error("Error:", error);
            showSnackbar(error.response?.data?.message || 'An error occurred while Adding the New Terminal.', 'error');
            setSubmitLoader(false)
        }
    }

    return (
        <>
            <PopupLayout open={open} onClose={onClose} title={"Add New Terminal"} handleSubmit={handleAddTerminal}>

                {/* <--------------------- Location Name ---------------------> */}
                <InputLayout size={4} title="Terminal Name" type="text" name="name" value={terminalName?.name} onChange={handleChange} placeholder="Enter Terminal Name" required={true} />


                <Col lg={4}>
                    <div className="input-container">
                        <label htmlFor="">Port City <RequiredAstrict /></label>
                        <PortDropDown dropDownValue={terminalName.city} dropDownState={terminalName} setDropDownState={setTerminalName} />
                    </div>
                </Col>

                <Col lg={4}>
                    <div className="input-container">
                        <label htmlFor="">State <RequiredAstrict /></label>
                        <StateDropDown dropDownValue={terminalName.state} dropDownState={terminalName} setDropDownState={setTerminalName} />
                    </div>
                </Col>

                <div className='d-flex justify-content-end gap-4'>
                    <button className='cancel-btn' onClick={onClose}>Cancel</button>
                    <LoaderBtn loader={submitLoader} title={"Add"} />
                </div>
            </PopupLayout>
        </>
    )
}

export default TerminalNameModal