import React, { createContext, useContext, useState } from "react";

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [accessToken, setAccessToken] = useState(localStorage.getItem("accessToken") || null);
    const [userDetails, setUserDetails] = useState(localStorage.getItem("userDetails") || {});
    const [navigation, setNavigation] = useState(localStorage.getItem("Navigation") || {});
    const [userRole, setUserRole] = useState(""); // Set an initial value for user role
    const [userName, setUserName] = useState(""); // Set an initial value for user ID
    const [userId, setUserId] = useState(""); // Set an initial value for user ID

    // Define functions to update context values
    const updateAccessToken = (newToken) => {
        // console.log("Setting token: ", newToken)
        setAccessToken(newToken);
        localStorage.setItem("accessToken", newToken);
    };

    const updateUserDetails = (newUserDetails) => {
        setUserDetails(newUserDetails);
        localStorage.setItem("userDetails", newUserDetails);
    };

    const updateNavigation = (newNavigation) => {
        setNavigation(newNavigation);
        localStorage.setItem("Navigation", newNavigation);
    };

    const updateUserRole = (newUserRole) => {
        setUserRole(newUserRole);
        localStorage.setItem("user-role", newUserRole)
        // You can optionally store user role in local storage here
    };

    const updateUserId = (newUserId) => {
        setUserId(newUserId);
        localStorage.setItem("user", newUserId);
        // You can optionally store user ID in local storage here
    };

    const updateUserName = (newUserName) => {
        setUserName(newUserName);
        localStorage.setItem("userName", newUserName);
        // You can optionally store user ID in local storage here
    };


    // Create a context value with the state and updater functions
    const contextValue = {
        accessToken,
        userDetails,
        navigation,
        userRole,
        userId,
        userName,
        updateAccessToken,
        updateUserDetails,
        updateNavigation,
        updateUserRole,
        updateUserId,
        updateUserName
    };

    return (
        <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
    )

};
